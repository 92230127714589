import React, { Component } from 'react';
import { AppConstant } from 'app_constants';
import { Container, Row, Col } from 'react-bootstrap';
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { PMCNotesResponse } from '../model/model';
import './step_2.scss';
import { ColDef } from 'ag-grid-community';
import { ProjectRequestDataStore } from '../stores/project-request-data-store';
import moment from 'moment';
import momenttimezone from 'moment-timezone';
import { dataStore } from '../../../../common/stores';
import { RoleBasedActionName } from 'shared/types/enum';
import { MasterDataComponentModulesName } from 'features/master-data-management/components/constants';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import history from '../../../../shared/components/header-component/component/history';
import { observer, inject } from 'mobx-react';
import { ProjectRequestStep2DataStore } from '../stores/project-request-step-2-data-store';
import { ProjectRequestDetails } from './common/project-request-details';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import img from 'assets/check_complete_done_green_success_icon.png';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import { AddNotes } from 'common/components/add-notes/add-notes';
import { Attachments } from 'common/components/attachments/attachments';
import { tariffAttachmentsGridColDef } from 'features/tariff-management/pmc-tariff/components/pmc-tariff-col-defs';
import { ProjectRequestStep1DataStore } from '../stores/project-request-step-1-data-store';
import { projectRequestDataStore, projectRequestStep2DataStore } from '../stores';
import { ReactSelect } from 'common/components/select/select';
import { Option } from 'shared/types/shared';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import { TariffOptionsContainer } from '../tariff-options-project/components';
import { tariffOptionsDataStoreProject } from '../tariff-options-project/stores';
import { TariffOptionsIndexDataStore } from '../tariff-options-project/stores/tariff-options-data-store';
import _ from 'lodash';
import { ThreeDots } from 'react-loader-spinner';

/* eslint-disable */
interface ProjectRequestStep2ContainerProps {
    projectRequestDataStore: ProjectRequestDataStore;
    projectRequestStep1DataStore: ProjectRequestStep1DataStore;
    projectRequestStep2DataStore: ProjectRequestStep2DataStore;
    tariffOptionsDataStoreProject: TariffOptionsIndexDataStore;
}

interface ProjectRequestStep2ContainerState {
    rowSelected: boolean,
    lastTariffNoteID: number
}

@inject('projectRequestDataStore','projectRequestStep1DataStore','projectRequestStep2DataStore','tariffOptionsDataStoreProject') 
@observer
export class ProjectRequestStep2Container extends Component<ProjectRequestStep2ContainerProps, ProjectRequestStep2ContainerState> {

    constructor(props: ProjectRequestStep2ContainerProps | Readonly<ProjectRequestStep2ContainerProps>) {
        super(props);
        this.state = {
            rowSelected: false,
            lastTariffNoteID: 0
        };
        dataStore.setModuleName('Project Request Step 2')
    }

    componentDidMount() {
      const {projectRequestStep2DataStore, projectRequestDataStore} = this.props;
      if(!(projectRequestDataStore.workflowId||projectRequestStep2DataStore.workflowId)){
           history.push('/ProjectDashboard')
      }else{
          projectRequestStep2DataStore.init(); 
          window.scroll(0,0);
      }
      window.history.pushState(null, document.title, window.location.href);
      window.addEventListener('popstate', this.backButtonDisabled)
    }

    componentWillUnmount() {
        const {projectRequestStep2DataStore, projectRequestDataStore} = this.props;
        projectRequestStep2DataStore.reset();
        window.removeEventListener('popstate', this.backButtonDisabled)
    }

    backButtonDisabled(event){
            window.history.pushState(null, document.title,  window.location.href);
            alert('Back button is not allowed in Project Request! Use \'Cancel\' to go back.')
    }

    componentDidUpdate() {
        const filters = localStorageService.get('Step 2_filter');
        this.props.projectRequestStep2DataStore.agGridService.setAllFilters(filters);
        const columnVisible = localStorageService.get('Step 2_' + accountStore.userName);
        this.props.projectRequestStep2DataStore.agGridService.setColumnVisibility(columnVisible);
    }

    onChangePipeline = (selectedList, selectedItem) => {
        this.props.projectRequestStep2DataStore.setPipeline(selectedList);
    };

    gridConfigTariffSummary: ColDef[] = [
        {
              headerName: 'Tariff Base',
              field: 'tariffBase',
              tooltipField: 'tariffBase',
              filter: true,
              editable: false,
              sortable: true,
              minWidth: 110,
              flex: 1
          },
          {
              headerName: 'Tariff Log',
              field: 'tariffLog',
              tooltipField: 'tariffLog',
              filter: true,
              sortable: true,
              minWidth: 110,
              editable: false,
              flex: 2
          }
    ]

    
    gridConfigInfrastructureSummary: ColDef[] = [
    {
          headerName: 'Pipeline',
          field: 'pipeline',
          tooltipField: 'pipeline',
          filter: true,
          editable: false,
          sortable: true,
          minWidth: 110,
          flex: 1
      },
      {
        headerName: 'Tariff Option',
        field: 'option',
        tooltipField: 'option',
        filter: true,
        sortable: true,
        minWidth: 110,
        editable: false,
        flex: 2
      },
      {
        headerName: 'Line Segment',
        field: 'lineSegment',
        tooltipField: 'lineSegment',
        filter: true,
        sortable: true,
        minWidth: 110,
        editable: false,
        flex: 2
      },
      {
          headerName: 'Location',
          field: 'location',
          tooltipField: 'location',
          filter: true,
          sortable: true,
          minWidth: 110,
          editable: false,
          flex: 1
      },
      {
          headerName: 'Connnection Point',
          field: 'cp',
          tooltipField: 'cp',
          filter: true,
          sortable: true,
          minWidth: 110,
          editable: false,
          flex: 2
      },
      {
        headerName: 'Customer Maintenance',
        field: 'customer',
        tooltipField: 'customer',
        filter: true,
        sortable: true,
        minWidth: 110,
        editable: false,
        flex: 2
     },
      {
          headerName: 'Agency Mtn',
          field: 'agency',
          tooltipField: 'agency',
          filter: true,
          sortable: true,
          minWidth: 110,
          editable: false,
          flex: 1
      },
      {
          headerName: 'Grade Mtn',
          field: 'grade',
          tooltipField: 'grade',
          filter: true,
          sortable: true,
          minWidth: 110,
          editable: false,
          flex: 2
      },
      {
          headerName: 'Profit Center',
          field: 'profitCenter',
          tooltipField: 'profitCenter',
          filter: true,
          sortable: true,
          minWidth: 110,
          editable: false,
          flex: 2
      },
]
    
    
    gridConfig: ColDef[] = [
        {
              headerName: 'Steps',
              field: 'workflowstepName',
              tooltipField: 'workflowstepName',
              filter: true,
              editable: false,
              sortable: true,
              minWidth: 150,
              singleClickEdit: true,
              flex: 2
          },
          {
              headerName: 'Status',
              field: 'workflowstepStatus',
              tooltipField: 'workflowstepStatus',
              filter: true,
              sortable: true,
              minWidth: 110,
              editable: false,
              singleClickEdit: true,
              suppressSizeToFit: true,
              flex: 1
          },
          {
              headerName: 'Comment',
              field: 'workflowStepComments',
              tooltipField: 'workflowStepComments',
              cellEditor: 'agSelectCellEditor',
              singleClickEdit: true,
              filter: true,
              sortable: true,
              editable: false,
              minWidth: 135,
              flex: 2
          },
          {
              headerName: 'Workflow Step Role',
              field: 'workflowstepRole',
              tooltipField: 'workflowstepRole',
              singleClickEdit: true,
              editable: false,
              minWidth: 120,
              suppressSizeToFit: false,
          },
          {
              headerName: 'Last Modified User *',
              field: 'lastModifiedUser',
              tooltipField: 'lastModifiedUser',
              singleClickEdit: true,
              editable: false,
              minWidth: 120,
              suppressSizeToFit: false,
          },
          {
              headerName: 'Last Modified Date',
              field: 'lastModifiedDate',
              tooltipField: 'lastModifiedDate',
              filter: true,
              sortable: true,
              editable: false,
              minWidth: 120,
              valueGetter: params => {
                  if (params.data['lastModifiedDate'] === null) {
                      return '';
                  } else {
                      return momenttimezone
                      .tz(moment.utc(params.data['lastModifiedDate']), AppConstant.CST_TIME_FORMATTER)
                      .format(AppConstant.DATE_TIME_FORMAT);
                  }
              },
              suppressSizeToFit: false,
          }
      ];
    
        

  getGridConfig(gridId) {
    const {
        projectRequestStep2DataStore: { agGridService }
    } = this.props;
    const { projectRequestStep2DataStore } = this.props;
    const rowData = projectRequestStep2DataStore.workflowStatusGridData;
    const gridParams: any = {
      rowData,
      columnDefs:this.gridConfig,
    };
    return agGridService.getGridConfig(gridParams, gridId);
  }

  getGridConfigInfrastructureSummary(gridId) {
    const {
        projectRequestStep2DataStore: { agGridService , linkedWorkflowData}
    } = this.props;
    const { projectRequestStep2DataStore } = this.props;
    const rowData = [];
    const gridParams: any = {
        rowData: linkedWorkflowData,
        columnDefs:this.gridConfigInfrastructureSummary,
    };
    return agGridService.getGridConfig(gridParams, gridId);
  }

  getGridConfigTariffSummary(gridId) {
    const {
        projectRequestStep2DataStore: { agGridService }
    } = this.props;
    const { projectRequestStep2DataStore } = this.props;
    const rowData = projectRequestStep2DataStore.formData2a.baseTariff ? [
        {tariffBase: projectRequestStep2DataStore.formData2a.baseTariff, tariffLog: projectRequestStep2DataStore.formData2a.tariffLog[0]}, 
        ...projectRequestStep2DataStore.formData2a.tariffLog.flatMap((x,index)=>{
            if(index!==0){
                return {tariffLog:x}
            }else{
                return []
            }
        })]: [];
        const gridParams: any = {
            rowData,
            columnDefs:this.gridConfigTariffSummary,
        };
        return agGridService.getGridConfig(gridParams, gridId);
    }

    showOnlyUS = () => {
        return this.props.projectRequestDataStore.accountStore.rolesJson[0]?.defaultLocation==='US'
    }

    goToPreviousPage = () => {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
        () => projectRequestStep2DataStore.goToPreviousPage(),
        'Warning',
        'This will redirect you back to Initiate Project - Step 1, and some changes may be lost!'
        );
    }

    setActiveTab = (e) => {
        projectRequestStep2DataStore.setActiveTab(e)
        if((e==='review'||e==='compare')){
            if(projectRequestStep2DataStore.tariffOptionLinked){
            this.props.tariffOptionsDataStoreProject.init(projectRequestStep2DataStore.formData2a, projectRequestStep2DataStore.workflowId, true, projectRequestStep2DataStore.activeTab);
            } else{
                this.props.tariffOptionsDataStoreProject.init(projectRequestStep2DataStore.formData2a, projectRequestStep2DataStore.workflowId,false, projectRequestStep2DataStore.activeTab);
            }
        }
    }

    onSaveNotes = () => {
        projectRequestStep2DataStore.saveTariffNotes();
    };

    onDeleteNote = (noteID) => {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(() => {
            projectRequestStep2DataStore.deleteTariffNote(noteID);
        },
            'Delete',
            'Are you sure you want to delete the selected note?'
        );
        projectRequestStep2DataStore.NotesGridData.length > 0 ? projectRequestStep2DataStore.isTariffNoteAdded = true : false;
    };

    onAddNewNote() {
        const workflowDocumentID = this.state.lastTariffNoteID - 1;
        const rowData = [{
            workflowDocumentID: workflowDocumentID,
            workflowID: projectRequestStep2DataStore.workflowId,
            notes: '',
            createUser: projectRequestStep2DataStore.username,
            createDate: moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
            updateUser: null,
            updateDate: null,
            workflow: null,
        } as unknown as any, ...projectRequestStep2DataStore.notesGridData];
        projectRequestStep2DataStore.setPMCTariffNoteGridData(rowData);
        this.setState({ ...this.state, lastTariffNoteID: workflowDocumentID });
        projectRequestStep2DataStore.NotesGridData.length > 0 ? projectRequestStep2DataStore.isTariffNoteAdded = true : false;
    };

    getAttachmentsColDef() {
        return tariffAttachmentsGridColDef;
    }

    getAttachmentsGridConfig() {
        const {
            projectRequestStep1DataStore: { fileUploadGridData, agGridServiceForAddAttachment },
        } = this.props;
        const tariffAttachmentsGridParams: any = {
            rowData: fileUploadGridData,
            columnDefs: this.getAttachmentsColDef(),
        };
        return agGridServiceForAddAttachment.getGridConfig(tariffAttachmentsGridParams);
    }

    uploadFile(e) {
        projectRequestStep2DataStore.uploadFile(e);
    };

    downloadAttachment(fileID) {
        projectRequestStep2DataStore.downloadAttachment(fileID);
    };

    deleteAttachment = async (files) => {
        const requestBody: any = await projectRequestStep2DataStore.agGridService.getSelectedRows();
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
            () => {
                projectRequestStep2DataStore.deleteAttachment(files);
            },
            'Delete',
            'Are you sure you want to delete the selected attachment?'
        );
    };

    updateAttachment = () => {
        projectRequestStep2DataStore.updateAttachment();
    }

    checkRoleAccessSave = () => {
        const a =  dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save, MasterDataComponentModulesName.ProjectRequest);
        console.log(a, 'roleaccess');
        return a;
    }
    
    isSaveDisabledForLineSegment = ()  => {
        return !dataStore.checkOperationAccessWithModule(RoleBasedActionName.Save,  MasterDataComponentModulesName.LineSegmentMaintenance);
    };
     
    isforwardWorflowStepDsabled = () => {
        const { projectRequestDataStore, projectRequestStep1DataStore, projectRequestStep2DataStore } = this.props;
        const a = projectRequestStep2DataStore.workflowStatusGridData.find((item) => item.workflowstepName === projectRequestStep2DataStore.workflowstepName);
        return a ? a.autocomplete && a.autocomplete === "Y": false
    }

     checkRoleAccessSaveCompleteTariffReview = () => {
        const pipelineIDs = this.props.projectRequestStep2DataStore.pipelinesDropdown.filter((x: any) =>  this.props.projectRequestStep2DataStore.formData.pipelineSystems && this.props.projectRequestStep2DataStore.formData.pipelineSystems.filter((i)=> i === x.plName).length > 0).map((y) => y.pipelineID);
        // const a =  dataStore.checkOperationAccessWithModuleAndPipelineId(RoleBasedActionName.Save, MasterDataComponentModulesName.CompleteTariffReview, this.props.projectRequestStep2DataStore.formDataInfrastructure.pipeline.map(x => x.value));
        const a =  dataStore.checkOperationAccessWithModuleAndPipelineId(RoleBasedActionName.Save, MasterDataComponentModulesName.CompleteTariffReview, pipelineIDs);
        console.log(a, 'roleaccess');
        return a;
     }

     
     checkRoleAccessSaveCompleteTariffApproval = () => {
        const pipelineIDs = this.props.projectRequestStep2DataStore.pipelinesDropdown.filter((x: any) =>  this.props.projectRequestStep2DataStore.formData.pipelineSystems && this.props.projectRequestStep2DataStore.formData.pipelineSystems.filter((i)=> i === x.plName).length > 0).map((y) => y.pipelineID);
        const a =  dataStore.checkOperationAccessWithModuleAndPipelineId(RoleBasedActionName.Save, MasterDataComponentModulesName.CompleteTariffApproval, pipelineIDs) ;
        console.log(a, 'roleaccess');
        return a;
     }

     
     checkRoleAccessSaveCompletePLAccountingApproval = () => {
        const pipelineIDs = this.props.projectRequestStep2DataStore.pipelinesDropdown.filter((x: any) =>  this.props.projectRequestStep2DataStore.formData.pipelineSystems && this.props.projectRequestStep2DataStore.formData.pipelineSystems.filter((i)=> i === x.plName).length > 0).map((y) => y.pipelineID);
        const a =  dataStore.checkOperationAccessWithModuleAndPipelineId(RoleBasedActionName.Save, MasterDataComponentModulesName.CompletePLAccountingApproval, pipelineIDs);
        console.log(a, 'roleaccess');
        return a;
     }

     
     checkRoleAccessSaveCompleteCommercialApproval = () => {
        const pipelineIDs = this.props.projectRequestStep2DataStore.pipelinesDropdown.filter((x: any) =>  this.props.projectRequestStep2DataStore.formData.pipelineSystems && this.props.projectRequestStep2DataStore.formData.pipelineSystems.filter((i)=> i === x.plName).length > 0).map((y) => y.pipelineID);
        const a =  dataStore.checkOperationAccessWithModuleAndPipelineId(RoleBasedActionName.Save, MasterDataComponentModulesName.CompleteCommercialApproval, pipelineIDs);
        console.log(a, 'roleaccess');
        return a;
     }

     
     checkRoleAccessSaveCompleteSchedulingApproval = () => {
        const pipelineIDs = this.props.projectRequestStep2DataStore.pipelinesDropdown.filter((x: any) =>  this.props.projectRequestStep2DataStore.formData.pipelineSystems && this.props.projectRequestStep2DataStore.formData.pipelineSystems.filter((i)=> i === x.plName).length > 0).map((y) => y.pipelineID);
        const a =  dataStore.checkOperationAccessWithModuleAndPipelineId(RoleBasedActionName.Save, MasterDataComponentModulesName.CompleteSchedulingApproval, pipelineIDs) ;
        console.log(a, 'roleaccess');
        return a;
     }

     disabledEntitiesForMaintenance2B2CProject = () => {
        return projectRequestStep2DataStore.selectedProjectRequestType === 'Maintenance 2B – Nomination Receipt / Delivery Connection Points' || projectRequestStep2DataStore.selectedProjectRequestType === 'Maintenance 2C – Cancelled Connection Point';
     }


     getTariffDetail(tariffNumber)
     {  
        const data = projectRequestStep2DataStore.reviewTariffDetails.find(p=>_.isEqual(Number(p.tariffNumber), Number(tariffNumber)))
       if(data)
        {
        projectRequestStep2DataStore.pmcTariffDetailID = data.pmcTariffDetailID
        projectRequestStep2DataStore.setTariffNoteGridData(data.pmcNotes===null?[]:data.pmcNotes)
        projectRequestStep2DataStore.setTariffAttachmentGridData(data.pmcDocs===null?[]:data.pmcDocs)
        projectRequestStep2DataStore.backupAttachmentPMCGridData = data.pmcDocs===null?[]:data.pmcDocs
        }
     }

     onPMCAddNewNote(item) {
       if(item.pmcNotes!==null)
        projectRequestStep2DataStore.setTariffNoteGridData(item.pmcNotes)

        projectRequestStep2DataStore.pmcTariffDetailID = item.pmcTariffDetailID
        const pmcTariffNoteID = this.state.lastTariffNoteID - 1;
        const rowData = [{
            pmcTariffNoteID: pmcTariffNoteID,
            pmcTariffDetailID: projectRequestStep2DataStore.pmcTariffDetailID,
            notes: '',
            createUser: projectRequestStep2DataStore.username,
            createDate: moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
            updateUser: null,
            updateDate: null,
            pmcTariffDetail: null,
        } as unknown as PMCNotesResponse, ...projectRequestStep2DataStore.notesPMCGridData];
        projectRequestStep2DataStore.setTariffNoteGridData(rowData);
        this.setState({ ...this.state, lastTariffNoteID: pmcTariffNoteID });
        projectRequestStep2DataStore.PMCNotesGridData.length > 0 ? projectRequestStep2DataStore.isPMCTariffNoteAdded = true : false;
    };


    onPMCSaveNotes = () => {
        projectRequestStep2DataStore.savePMCTariffNotes();
    };

    onPMCDeleteNote = (noteID) => {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(() => {
            projectRequestStep2DataStore.deletePMCTariffNote(noteID);
        },
            'Delete',
            'Are you sure you want to delete the selected note?'
        );
        projectRequestStep2DataStore.PMCNotesGridData.length > 0 ? projectRequestStep2DataStore.isPMCTariffNoteAdded = true : false;
    };

   
    uploadPMCFile(e, item) {
       if(projectRequestStep2DataStore.pmcTariffDetailID===undefined)
        projectRequestStep2DataStore.pmcTariffDetailID =item.pmcTariffDetailID        
        if(item.pmcDocs!==null && _.isEqual(Number(projectRequestStep2DataStore.pmcTariffDetailID), Number(item.pmcTariffDetailID)))
            {
         projectRequestStep2DataStore.setTariffAttachmentGridData(item.pmcDocs)
         projectRequestStep2DataStore.backupAttachmentPMCGridData = item.pmcDocs
            }
         projectRequestStep2DataStore.uploadPMCFile(e);
    };

    downloadPMCAttachment(fileID) {
        projectRequestStep2DataStore.downloadPMCAttachment(fileID);
    };

    deletePMCAttachment = async (files) => {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
            () => {
                projectRequestStep2DataStore.deletePMCAttachment(files);
            },
            'Delete',
            'Are you sure you want to delete the selected attachment?'
        );
    };

    updatePMCAttachment = () => {       
        projectRequestStep2DataStore.updatePMCAttachment();
    }

    render(): React.ReactNode {
        const { projectRequestDataStore, projectRequestStep1DataStore, projectRequestStep2DataStore } = this.props;
        
        return (
            <div className='ProjectRequestBoxStep2'>
                <Container fluid>
                    <>
                        {projectRequestStep2DataStore.loader ? <div className="loader__overlay" /> : null}
                        <div className="loader__icon">
                            <ThreeDots height={80} width={80} color="green" ariaLabel="loading" visible = {projectRequestStep2DataStore.loader}/>
                        </div>
                    </>
                    <div className='boxes'>
                        <ProjectRequestDetails datastore={this.props.projectRequestStep2DataStore} projectRequestDatastore={projectRequestDataStore} showOnlyUS={this.showOnlyUS} onChangePipeline={this.onChangePipeline} allDisabled={true} checkRoleAccessSave={this.checkRoleAccessSave()}/>
                        {this.showOnlyUS() && (
                            <Tabs
                                defaultActiveKey={projectRequestStep2DataStore.activeTab}
                                activeKey={projectRequestStep2DataStore.activeTab}
                                className="screenHeaderTabs"
                                onSelect={(e)=>this.setActiveTab(e)}
                            >
                                <Tab eventKey='tariffSetup' title="Tariff Setup">
                                    <div className='selectContainer'>
                                        <div style={{ backgroundColor: '#eeeeee', border: '#333333', marginTop: '10px', padding: '5px 5px 5px 30px' }}>
                                            <h6>Tariff Setup</h6>
                                        </div>
                                        <div style={{ padding: '5px 5px 5px 30px' }}>
                                            <Row style={{ marginTop: '0px' }}>
                                                <Col style={{ marginTop: '5px', 'display': 'inline-flex' }}>
                                                    <ReactSelect
                                                        className='search_text_field'
                                                        values={''}
                                                        placeholder={'Select Base Tariff'}
                                                        // disabled={!(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') || ! (Array.isArray(projectRequestStep2DataStore.baseTariffDropdown) && projectRequestStep2DataStore.baseTariffDropdown.length)}
                                                        disabled={true}
                                                        options={Array.isArray(projectRequestStep2DataStore.baseTariffDropdown) && projectRequestStep2DataStore.baseTariffDropdown.length && projectRequestStep2DataStore.baseTariffDropdown.map(option=> {return{value: option.baseTariff, label: option.baseTariff }}) ||  []}
                                                        onChange={(e)=>{projectRequestStep2DataStore.changeFormData2a((e as Option)?.value, 'baseTariff')}}
                                                        isMulti={false}
                                                        isSearchable={true}
                                                    />
                                                    <CustomButton disabled={true} type={CustomButtonType.Submit} onClick={() => { history.push('/BaseTariff', { from: 'step2', workflowId: projectRequestStep2DataStore.workflowId }) }}>
                                                        +
                                                </CustomButton>
                                                    {projectRequestStep2DataStore.baseLinked && (<img style={{ height: '25px', 'marginLeft': '10px', 'marginTop': '5px' }} src={img} alt="Synced" />)}
                                                </Col>
                                                <Col></Col><Col></Col>
                                            </Row>
                                            <Row style={{ marginTop: '5px' }}>
                                                {projectRequestStep2DataStore.formData2a.baseTariffarray && projectRequestStep2DataStore.formData2a.baseTariffarray.map((x) => <div style={{ backgroundColor: '#eeeeee', padding: '5px', border: '1px solid', 'width': 'fit-content', 'marginLeft': '22px', 'marginTop': '5px', 'display': 'inline-flex' }}>
                                                        {/* {this.checkRoleAccessSave()&&(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') && !(!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '') && <div style={{ cursor: 'pointer', 'marginRight': '5px', 'color': 'red' }} onClick={(e) => { projectRequestStep2DataStore.changeFormData2a(x, 'tariffLogDelete') }}>
                                                            X
                                                        </div>} */}
                                                        {x}
                                                    </div>
                                                )}
                                            </Row>
                                            <Row style={{ marginTop: '0px' }}>
                                                <Col style={{ marginTop: '10px', 'display': 'inline-flex' }}>
                                                    <ReactSelect
                                                        className='search_text_field'
                                                        values={''}
                                                        placeholder={'Select Tariff Log/s'}
                                                        disabled={true}
                                                        // disabled={!(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') ||projectRequestStep2DataStore.formData2a.baseTariff === ''}
                                                        options={Array.isArray(projectRequestStep2DataStore.tariffLogDropdown) && projectRequestStep2DataStore.tariffLogDropdown.length && projectRequestStep2DataStore.tariffLogDropdown.map(option=> {return{value: option.tariffNumber, label: option.tariffNumber }}) ||  []}
                                                        onChange={(e)=>{projectRequestStep2DataStore.changeFormData2a((e as Option)?.value, 'tariffLog')}}
                                                        isMulti={false}
                                                        isSearchable={true}
                                                    />
                                                    <CustomButton disabled={true} type={CustomButtonType.Submit} onClick={() => { history.push('/TariffLog') }}>
                                                        +
                                                </CustomButton>
                                                    {projectRequestStep2DataStore.tariffLogLinked && (<img style={{ height: '25px', 'marginLeft': '10px','marginTop': '5px' }} src={img} alt="Synced" />)}
                                                </Col>
                                                <Col></Col><Col></Col>
                                            </Row>
                                            <Row style={{ marginTop: '5px' }}>
                                                {projectRequestStep2DataStore.formData2a.tariffLog.length > 0 && projectRequestStep2DataStore.formData2a.tariffLog.map(x =>
                                                    <div style={{ backgroundColor: '#eeeeee', padding: '5px', border: '1px solid', 'width': 'fit-content', 'marginLeft': '22px', 'marginTop': '5px', 'display': 'inline-flex' }}>
                                                        {/* {this.checkRoleAccessSave()&&(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') && !(!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '') && <div style={{ cursor: 'pointer', 'marginRight': '5px', 'color': 'red' }} onClick={(e) => { projectRequestStep2DataStore.changeFormData2a(x, 'tariffLogDelete') }}>
                                                            X
                                                        </div>} */}
                                                        {x}
                                                    </div>
                                                )}
                                            </Row>
                                        </div>
                                        <div style={{ backgroundColor: '#eeeeee', border: '#333333', marginTop: '10px', padding: '5px 5px 5px 30px' }}>
                                            <h6>Tariff Summary</h6>
                                        </div>
                                        <div style={{ padding: '5px 5px 5px 30px' }}>
                                            <div className="innerflexwidths">
                                                <Row style={{ 'border': '1px solid #a3d0e4', 'padding': '5px', 'width': '98%', 'marginLeft': '10px', 'marginRight': '10px', 'marginTop': '5px' }}>Linked Items</Row>
                                                <Row>
                                                    <AgGridComponent gridConfig={this.getGridConfigTariffSummary('Step2 Tariff Summary')} />
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="infrastructureSetup" title="Infrastructure Setup">
                                <div className='selectContainer'>
                                        <div style={{ backgroundColor: '#eeeeee', border: '#333333', marginTop: '10px', padding: '5px 5px 5px 30px' }}>
                                            <h6>Infrastructure Setup</h6>
                                        </div>
                                        <div style={{ padding: '5px 5px 5px 30px' }}>
                                        <Row style={{ marginTop: '0px' }}>
                                                <Col style={{ marginTop: '10px'}}>
                                                <div style={{display: 'inline-flex'}}>
                                                    <ReactSelect
                                                        className='search_text_field'
                                                        values={''}
                                                        placeholder={'Pipeline'}
                                                        disabled={!this.checkRoleAccessSave()||!(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') || !(Array.isArray(projectRequestStep2DataStore.pipelinesDropdown) && projectRequestStep2DataStore.pipelinesDropdown.length) || (!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '')}
                                                        options={Array.isArray(projectRequestStep2DataStore.pipelinesDropdown) && projectRequestStep2DataStore.pipelinesDropdown.length && projectRequestStep2DataStore.pipelinesDropdown.map(option=> {return{value: option.pipelineID, label: option.plName }}) ||  []}
                                                        onChange={(e)=>{const event = e as Option[] ; event.map((x) => projectRequestStep2DataStore.changeFormDataInfrastructure((x as Option), 'pipeline'))}}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    />
                                                    <CustomButton type={CustomButtonType.Submit} disabled={true} onClick={() => { history.push('/AddPipeline') }}>
                                                        +
                                                    </CustomButton>
                                                    </div>
                                                    {projectRequestStep2DataStore.pipelineLinked && (<img style={{ height: '25px', 'marginLeft': '10px' }} src={img} alt="Synced" />)}
                                                    <Row style={{ marginTop: '5px' }}>
                                                        {projectRequestStep2DataStore.formDataInfrastructure.pipeline.length > 0 && projectRequestStep2DataStore.formDataInfrastructure.pipeline.map((x:any) =>
                                                            <div style={{ backgroundColor: '#eeeeee', padding: '5px', border: '1px solid', 'width': 'fit-content', 'marginLeft': '22px', 'marginTop': '5px', 'display': 'inline-flex' }}>
                                                                {this.checkRoleAccessSave()&&(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') && !(!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '') && <div style={{ cursor: 'pointer', 'marginRight': '5px', 'color': 'red' }} onClick={(e) => { projectRequestStep2DataStore.changeFormDataInfrastructure(x, 'pipelineDelete') }}>
                                                                    X
                                                                </div>}
                                                                {x.label}
                                                            </div>
                                                        )}
                                                    </Row>
                                                </Col>
                                                <Col style={{ marginTop: '10px'}}>
                                                <div style={{display: 'inline-flex'}}>
                                                    <ReactSelect
                                                        className='search_text_field'
                                                        values={''}
                                                        placeholder={'Connection Point'}
                                                        disabled={!this.checkRoleAccessSave()||!(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') || !(Array.isArray(projectRequestStep2DataStore.cpDropdown) && projectRequestStep2DataStore.cpDropdown.length) || (!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '')}
                                                        options={Array.isArray(projectRequestStep2DataStore.cpDropdown) && projectRequestStep2DataStore.cpDropdown.length && projectRequestStep2DataStore.cpDropdown.map(option=> {return{value: option.connectionPointID, label: option.cpName }}) ||  []}
                                                        onChange={(e)=>{const event = e as Option[] ; event.map((x) => projectRequestStep2DataStore.changeFormDataInfrastructure((x as Option), 'cp'))}}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    />
                                                    <CustomButton type={CustomButtonType.Submit} disabled={!this.checkRoleAccessSave()||!(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') || (!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '')|| this.disabledEntitiesForMaintenance2B2CProject() } onClick={() => { history.push('/ConnectionPointDetails', {from: '/project-request', url: projectRequestStep2DataStore.url,  workflowId: projectRequestStep2DataStore.workflowId, location: [...projectRequestStep2DataStore.formDataInfrastructure?.location?.map(x=>{return {label: x.label, value: x.value}})], pipeline:[...projectRequestStep2DataStore.formDataInfrastructure?.pipeline?.map(x=>{return {label: x.label, value: x.value}})] })}}>
                                                        +
                                                    </CustomButton>
                                                    </div>
                                                    {projectRequestStep2DataStore.connectionPointLinked && (<img style={{ height: '25px', 'marginLeft': '10px' }} src={img} alt="Synced" />)}
                                                    <Row style={{ marginTop: '5px' }}>
                                                        {projectRequestStep2DataStore.formDataInfrastructure.cp.length > 0 && projectRequestStep2DataStore.formDataInfrastructure.cp.map((x:any) =>
                                                            <div style={{ backgroundColor:  x.value ? '#eeeeee' : 'red', padding: '5px', border: '1px solid', 'width': 'fit-content', 'marginLeft': '22px', 'marginTop': '5px', 'display': 'inline-flex' }}>
                                                                {this.checkRoleAccessSave()&&(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') && !(!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '') && <div style={{ cursor: 'pointer', 'marginRight': '5px', 'color':  x.value ? 'red' : '#FFFFFF' }} onClick={(e) => { projectRequestStep2DataStore.changeFormDataInfrastructure(x, 'cpDelete') }}>
                                                                    X
                                                                </div>}
                                                                {x.label}
                                                            </div>
                                                        )}
                                                    </Row>
                                                </Col>
                                                <Col style={{ marginTop: '10px'}}>
                                                <div style={{display: 'inline-flex'}}>
                                                    <ReactSelect
                                                        className='search_text_field'
                                                        values={''}
                                                        placeholder={'Profit Center'}
                                                        disabled={!this.checkRoleAccessSave()||!(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') || ! (Array.isArray(projectRequestStep2DataStore.profitCenterDropdown) && projectRequestStep2DataStore.profitCenterDropdown.length) || (!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '') || projectRequestStep2DataStore.selectedProjectRequestType === '1D - New Tariff Option with New Origin/Destination'|| this.disabledEntitiesForMaintenance2B2CProject()}
                                                        options={Array.isArray(projectRequestStep2DataStore.profitCenterDropdown) && projectRequestStep2DataStore.profitCenterDropdown.length && projectRequestStep2DataStore.profitCenterDropdown.map(option=> {return{value: option.profitCenterID, label: option.profitCenterDesc }}) ||  []}
                                                        onChange={(e)=>{const event = e as Option[] ; event.map((x) => projectRequestStep2DataStore.changeFormDataInfrastructure((x as Option), 'profitCenter'))}}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    />
                                                    <CustomButton type={CustomButtonType.Submit} disabled={true} onClick={() => { history.push('/ProfitCenterMaintenance') }}>
                                                        +
                                                    </CustomButton>
                                                    </div>
                                                    {projectRequestStep2DataStore.profitCenterLinked && (<img style={{ height: '25px', 'marginLeft': '10px' }} src={img} alt="Synced" />)}
                                                    <Row style={{ marginTop: '5px' }}>
                                                        {projectRequestStep2DataStore.formDataInfrastructure.profitCenter.length > 0 && projectRequestStep2DataStore.formDataInfrastructure.profitCenter.map((x:any) =>
                                                            <div style={{ backgroundColor: '#eeeeee', padding: '5px', border: '1px solid', 'width': 'fit-content', 'marginLeft': '22px', 'marginTop': '5px', 'display': 'inline-flex' }}>
                                                                {this.checkRoleAccessSave()&&(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') && !(!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '') && <div style={{ cursor: 'pointer', 'marginRight': '5px', 'color': 'red' }} onClick={(e) => { projectRequestStep2DataStore.changeFormDataInfrastructure(x, 'profitCenterDelete') }}>
                                                                    X
                                                                </div>}
                                                                {x.label}
                                                            </div>
                                                        )}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                            <Col style={{ marginTop: '10px'}}>
                                                <div style={{display: 'inline-flex'}}>
                                                    <ReactSelect
                                                        className='search_text_field'
                                                        values={''}
                                                        placeholder={'Location'}
                                                        disabled={!this.checkRoleAccessSave()||!(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') || ! (Array.isArray(projectRequestStep2DataStore.locationDropdown) && projectRequestStep2DataStore.locationDropdown.length && projectRequestStep2DataStore.formDataInfrastructure.pipeline.length ) || (!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '')}
                                                        options={Array.isArray(projectRequestStep2DataStore.locationDropdown) && projectRequestStep2DataStore.locationDropdown.length && projectRequestStep2DataStore.locationDropdown.map(option=> {return{value: option.locationID, label:  option.locnName }}) ||  []}
                                                        onChange={(e)=>{const event = e as Option[] ; event.map((x) => projectRequestStep2DataStore.changeFormDataInfrastructure((x as Option), 'location'))}}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    />
                                                    <CustomButton type={CustomButtonType.Submit} disabled={!this.checkRoleAccessSave()||!(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') || (!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '') || this.disabledEntitiesForMaintenance2B2CProject()} onClick={() => { history.push('/LocationDetails', {from: '/project-request', url: projectRequestStep2DataStore.url,  workflowId: projectRequestStep2DataStore.workflowId })}}>
                                                        +
                                                    </CustomButton>
                                                    </div>
                                                    {projectRequestStep2DataStore.locationLinked && (<img style={{ height: '25px', 'marginLeft': '10px' }} src={img} alt="Synced" />)}
                                                    <Row style={{ marginTop: '5px' }}>
                                                        {projectRequestStep2DataStore.formDataInfrastructure.location.length > 0 && projectRequestStep2DataStore.formDataInfrastructure.location.map((x:any) =>
                                                            <div style={{ backgroundColor: x.value ? '#eeeeee' : 'red', padding: '5px', border: '1px solid', 'width': 'fit-content', 'marginLeft': '22px', 'marginTop': '5px', 'display': 'inline-flex' }}>
                                                                {this.checkRoleAccessSave()&&(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') && !(!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '') && <div style={{ cursor: 'pointer', 'marginRight': '5px', 'color':  x.value ? 'red' : '#FFFFFF' }} onClick={(e) => { projectRequestStep2DataStore.changeFormDataInfrastructure(x, 'locationDelete') }}>
                                                                    X
                                                                </div>}
                                                                {x.label}
                                                            </div>
                                                        )}
                                                    </Row>
                                                </Col>
                                                <Col style={{ marginTop: '10px'}}>
                                                <div style={{display: 'inline-flex'}}>
                                                    <ReactSelect
                                                        className='search_text_field'
                                                        values={''}
                                                        placeholder={'Customer Maintenance'}
                                                        disabled={!this.checkRoleAccessSave()||!(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') || ! (Array.isArray(projectRequestStep2DataStore.customerMaintenanceDropdown) && projectRequestStep2DataStore.customerMaintenanceDropdown.length && projectRequestStep2DataStore.formDataInfrastructure.pipeline.length)  || (!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '') || projectRequestStep2DataStore.selectedProjectRequestType === '1D - New Tariff Option with New Origin/Destination' || this.disabledEntitiesForMaintenance2B2CProject()}
                                                        options={Array.isArray(projectRequestStep2DataStore.customerMaintenanceDropdown) && projectRequestStep2DataStore.customerMaintenanceDropdown.length && projectRequestStep2DataStore.customerMaintenanceDropdown.map(option=> {return{value: option.customerID, label: option.customerName }}) ||  []}
                                                        onChange={(e)=>{const event = e as Option[] ; event.map((x) => projectRequestStep2DataStore.changeFormDataInfrastructure((x as Option), 'customer'))}}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    />
                                                    <CustomButton type={CustomButtonType.Submit} disabled={true} onClick={() => { history.push('/ProfitCenterMaintenance') }}>
                                                        +
                                                    </CustomButton>
                                                    </div>
                                                    {projectRequestStep2DataStore.customerMaintenanceLinked && (<img style={{ height: '25px', 'marginLeft': '10px' }} src={img} alt="Synced" />)}
                                                    <Row style={{ marginTop: '5px' }}>
                                                        {projectRequestStep2DataStore.formDataInfrastructure.customer.length > 0 && projectRequestStep2DataStore.formDataInfrastructure.customer.map((x:any) =>
                                                            <div style={{ backgroundColor: '#eeeeee', padding: '5px', border: '1px solid', 'width': 'fit-content', 'marginLeft': '22px', 'marginTop': '5px', 'display': 'inline-flex' }}>
                                                                {this.checkRoleAccessSave()&&(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') && !(!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '') && <div style={{ cursor: 'pointer', 'marginRight': '5px', 'color': 'red' }} onClick={(e) => { projectRequestStep2DataStore.changeFormDataInfrastructure(x, 'customerDelete') }}>
                                                                    X
                                                                </div>}
                                                                {x.label}
                                                            </div>
                                                        )}
                                                    </Row>
                                                </Col>
                                                <Col style={{ marginTop: '10px'}}>
                                                <div style={{display: 'inline-flex'}}>
                                                    <ReactSelect
                                                        className='search_text_field'
                                                        values={''}
                                                        placeholder={'Grade Maintenance'}
                                                        disabled={!this.checkRoleAccessSave()||!(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2')  || ! (Array.isArray(projectRequestStep2DataStore.gradeMaintenanceDropdown) && projectRequestStep2DataStore.gradeMaintenanceDropdown.length) || (!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '') || projectRequestStep2DataStore.selectedProjectRequestType === '1D - New Tariff Option with New Origin/Destination' || this.disabledEntitiesForMaintenance2B2CProject()}
                                                        options={Array.isArray(projectRequestStep2DataStore.gradeMaintenanceDropdown) && projectRequestStep2DataStore.gradeMaintenanceDropdown.length && projectRequestStep2DataStore.gradeMaintenanceDropdown.map(option=> {return{value: option.gradeID, label: option.gradeName }}) ||  []}
                                                        onChange={(e)=>{const event = e as Option[] ; event.map((x) => projectRequestStep2DataStore.changeFormDataInfrastructure((x as Option), 'gradeMaintenance'))}}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    />
                                                    <CustomButton type={CustomButtonType.Submit} disabled={true} onClick={() => { history.push('/ProfitCenterMaintenance') }}>
                                                        +
                                                    </CustomButton>
                                                    </div>
                                                    {projectRequestStep2DataStore.gradeMaintenanceLinked && (<img style={{ height: '25px', 'marginLeft': '10px' }} src={img} alt="Synced" />)}
                                                    <Row style={{ marginTop: '5px' }}>
                                                        {projectRequestStep2DataStore.formDataInfrastructure.gradeMaintenance.length > 0 && projectRequestStep2DataStore.formDataInfrastructure.gradeMaintenance.map((x:any) =>
                                                            <div style={{ backgroundColor: '#eeeeee', padding: '5px', border: '1px solid', 'width': 'fit-content', 'marginLeft': '22px', 'marginTop': '5px', 'display': 'inline-flex' }}>
                                                               {this.checkRoleAccessSave()&&(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') && !(!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '') && <div style={{ cursor: 'pointer', 'marginRight': '5px', 'color': 'red' }} onClick={(e) => { projectRequestStep2DataStore.changeFormDataInfrastructure(x, 'gradeMaintenanceDelete') }}>
                                                                    X
                                                                </div>}
                                                                {x.label}
                                                            </div>
                                                        )}
                                                    </Row>
                                                </Col>
                                            </Row>
                                            <Row>
                                            <Col style={{ marginTop: '10px'}}>
                                                <div style={{display: 'inline-flex'}}>
                                                    <ReactSelect
                                                        className='search_text_field'
                                                        values={''}
                                                        placeholder={'Line Segment'}
                                                        disabled={!this.checkRoleAccessSave()||!(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2')  || ! (Array.isArray(projectRequestStep2DataStore.lineSegmentDropdown) && projectRequestStep2DataStore.lineSegmentDropdown.length) || (!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '') || this.disabledEntitiesForMaintenance2B2CProject()}
                                                        options={Array.isArray(projectRequestStep2DataStore.lineSegmentDropdown) && projectRequestStep2DataStore.lineSegmentDropdown.length && projectRequestStep2DataStore.lineSegmentDropdown.map(option=> {return{value: option.lineSegID, label: option.lineSegDesc }}) ||  []}
                                                        onChange={(e)=>{const event = e as Option[] ; event.map((x) => projectRequestStep2DataStore.changeFormDataInfrastructure((x as Option), 'lineSegment'))}}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    />
                                                    <CustomButton type={CustomButtonType.Submit} disabled={!this.checkRoleAccessSave() || this.isSaveDisabledForLineSegment() || !(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') || (!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '') || this.disabledEntitiesForMaintenance2B2CProject()}onClick={() => { history.push('/AddEditLineSegmentMaintenance', {from: '/project-request', url: projectRequestStep2DataStore.url,  workflowId: projectRequestStep2DataStore.workflowId, location: [...projectRequestStep2DataStore.formDataInfrastructure?.location?.map(x=>{return {label: x.label, value: x.value}})], pipeline:[...projectRequestStep2DataStore.formDataInfrastructure?.pipeline?.map(x=>{return {label: x.label, value: x.value}})], connectionPoint: [...projectRequestStep2DataStore.formDataInfrastructure?.cp?.map(x=>{return {label: x.label, value: x.value}})] })}}>
                                                        +
                                                    </CustomButton>
                                                    </div>
                                                    {projectRequestStep2DataStore.lineSegmentLinked && (<img style={{ height: '25px', 'marginLeft': '10px' }} src={img} alt="Synced" />)}
                                                    <Row style={{ marginTop: '5px' }}>
                                                        {projectRequestStep2DataStore.formDataInfrastructure.lineSegment.length > 0 && projectRequestStep2DataStore.formDataInfrastructure.lineSegment.map((x:any) =>
                                                            <div style={{ backgroundColor:  x.value ? '#eeeeee' : 'red', padding: '5px', border: '1px solid', 'width': 'fit-content', 'marginLeft': '22px', 'marginTop': '5px', 'display': 'inline-flex' }}>
                                                                {this.checkRoleAccessSave()&&(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') && !(!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '') && <div style={{ cursor: 'pointer', 'marginRight': '5px', 'color':  x.value ? 'red' : '#FFFFFF' }} onClick={(e) => { projectRequestStep2DataStore.changeFormDataInfrastructure(x, 'lineSegmentDelete') }}>
                                                                    X
                                                                </div>}
                                                                {x.label}
                                                            </div>
                                                        )}
                                                    </Row>
                                                </Col>
                                                <Col style={{ marginTop: '10px'}}>
                                                <div style={{display: 'inline-flex'}}>
                                                    <ReactSelect
                                                        className='search_text_field'
                                                        values={''}
                                                        placeholder={'Agency'}
                                                        disabled={!this.checkRoleAccessSave()||!(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2')  || ! (Array.isArray(projectRequestStep2DataStore.agencyDropdown) && projectRequestStep2DataStore.agencyDropdown.length) || (!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '') || projectRequestStep2DataStore.selectedProjectRequestType === '1D - New Tariff Option with New Origin/Destination' || this.disabledEntitiesForMaintenance2B2CProject()}
                                                        options={Array.isArray(projectRequestStep2DataStore.agencyDropdown) && projectRequestStep2DataStore.agencyDropdown.length && projectRequestStep2DataStore.agencyDropdown.map(option=> {return{value: option.agencyid, label: option.agencyname }}) ||  []}
                                                        onChange={(e)=>{const event = e as Option[] ; event.map((x) => projectRequestStep2DataStore.changeFormDataInfrastructure((x as Option), 'agency'))}}
                                                        isMulti={true}
                                                        isSearchable={true}
                                                    />
                                                    <CustomButton type={CustomButtonType.Submit} disabled={ true } onClick={() => { history.push('/Agency') }}>
                                                        +
                                                    </CustomButton>
                                                    </div>
                                                    {projectRequestStep2DataStore.agencyLinked && (<img style={{ height: '25px', 'marginLeft': '10px' }} src={img} alt="Synced" />)}
                                                    <Row style={{ marginTop: '5px' }}>
                                                        {projectRequestStep2DataStore.formDataInfrastructure.agency.length > 0 && projectRequestStep2DataStore.formDataInfrastructure.agency.map((x:any) =>
                                                            <div style={{ backgroundColor: '#eeeeee', padding: '5px', border: '1px solid', 'width': 'fit-content', 'marginLeft': '22px', 'marginTop': '5px', 'display': 'inline-flex' }}>
                                                                {this.checkRoleAccessSave()&&(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') && !(!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '') && <div style={{ cursor: 'pointer', 'marginRight': '5px', 'color': 'red' }} onClick={(e) => { projectRequestStep2DataStore.changeFormDataInfrastructure(x, 'agencyDelete') }}>
                                                                    X
                                                                </div>}
                                                                {x.label}
                                                            </div>
                                                        )}
                                                    </Row>
                                                </Col>
                                            <Col></Col>
                                            </Row>
                                        </div>
                                        <div style={{ backgroundColor: '#eeeeee', border: '#333333', marginTop: '10px', padding: '5px 5px 5px 30px' }}>
                                            <h6>Tariff Options Setup</h6>
                                        </div>
                                        <div style={{ padding: '5px 5px 5px 30px' }}>
                                            <Row>
                                            <Col style={{ marginTop: '5px' }}>
                                                    <CustomButton type={CustomButtonType.Submit}  disabled={!this.checkRoleAccessSave()||!(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') ||!projectRequestStep2DataStore.tariffLogLinked} onClick={() => {projectRequestStep2DataStore.processTariffOptions()}}>
                                                    Process Tariff Options
                                                    </CustomButton>
                                                    {projectRequestStep2DataStore.tariffOptionLinked && (<img style={{ height: '25px', 'marginLeft': '10px' }} src={img} alt="Synced" />)}
                                                    <CustomButton type={CustomButtonType.Submit} disabled={!this.checkRoleAccessSave()||!(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') ||!projectRequestStep2DataStore.tariffLogLinked} onClick={() => {history.push('\TariffOptions', {from: 'project-request-infrastructure', url: projectRequestStep2DataStore.url, baseTariff: projectRequestStep2DataStore.formData2a.baseTariff})}}>
                                                    Create Tariff Option +
                                                    </CustomButton>
                                                    </Col>
                                                    <Col>
                                                    </Col><Col></Col>
                                            </Row>
                                            <Row style={{ marginTop: '5px' }}>
                                                {Array.isArray(projectRequestStep2DataStore.tariffOptions) && projectRequestStep2DataStore.tariffOptions.length > 0 && projectRequestStep2DataStore.tariffOptions.map((x:any) =>
                                                    <div style={{ backgroundColor: '#eeeeee', padding: '5px', border: '1px solid', 'width': 'fit-content', 'marginLeft': '22px', 'marginTop': '5px', 'display': 'inline-flex', 'cursor': 'pointer' }} onClick={() => { history.push('/TariffOptions', { from: 'project-request-infrastructure', url: projectRequestStep2DataStore.url, tariffName: x.tariffName, baseId: x.baseId, baseTariff: x.baseTariff, tariffLogId: x.logId, isCTSCommetsEnabled: projectRequestStep2DataStore.checkIsCTSCommentsEnabled(), isCreateNewEntityDisabled: this.disabledEntitiesForMaintenance2B2CProject(), disabled: !(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') || (!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '')})}}>
                                                        {/* <div style={{ cursor: 'pointer', 'marginRight': '5px', 'color': 'red' }} onClick={(e) => { projectRequestStep2DataStore.changeFormDataInfrastructure(x, 'lineSegmentDelete') }}>
                                                            X
                                                        </div> */}
                                                        View/Edit Tariff Option: {x.tariffNo}
                                                    </div>
                                                )}
                                            </Row>
                                        </div>
                                        <div style={{ backgroundColor: '#eeeeee', border: '#333333', marginTop: '10px', padding: '5px 5px 5px 30px' }}>
                                            <h6>Infrastructure Summary</h6>
                                        </div>
                                        <div style={{ padding: '5px 5px 5px 30px' }}>
                                                <Row style={{ 'border': '1px solid #a3d0e4', 'padding': '5px', 'width': '98%', 'marginLeft': '10px', 'marginRight': '10px', 'marginTop': '5px' }}>Linked Items</Row>
                                                <Row>
                                                    <AgGridComponent gridConfig={this.getGridConfigInfrastructureSummary('Step2 Tariff Infrastructure')} />
                                                </Row>
                                        </div>
                                    </div>
                                </Tab>
                                {/* disabled={projectRequestStep2DataStore.href==='/project-request-step-2'} */}
                                <Tab eventKey="review" title="Review" >
                                    <div className='selectContainer'>
                                        {/* <div style={{ backgroundColor: '#eeeeee', border: '#333333', marginTop: '10px', padding: '5px 5px 5px 30px' }}>
                                            <h6>Tariff</h6>
                                        </div> */}
                                        <div style={{ backgroundColor: '#eeeeee', border: '#333333', marginTop: '10px', padding: '5px 5px 5px 30px' }}>
                                            <h6>Infrastructure</h6>
                                        </div>
                                        <React.Fragment>
                                            <TariffOptionsContainer key = {'review'} tariffOptionsDataStoreProject={tariffOptionsDataStoreProject} baseTariffLogDetails={projectRequestStep2DataStore.formData2a} tariffOptionsLinked={projectRequestStep2DataStore.tariffOptionLinked} compare={false}/>
                                        </React.Fragment>

                                    </div>
                                </Tab>
                                <Tab eventKey="compare" title="Compare" disabled={projectRequestStep2DataStore.href==='/project-request-step-2' && !(!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '')}>
                                    <div className='selectContainer'>
                                        <React.Fragment>
                                            <TariffOptionsContainer key= {'compare'} tariffOptionsDataStoreProject={tariffOptionsDataStoreProject} baseTariffLogDetails={projectRequestStep2DataStore.formData2a} tariffOptionsLinked={projectRequestStep2DataStore.tariffOptionLinked} compare={true} allDisabled={!(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options' || projectRequestStep2DataStore.href==='/project-request-step-2') || (!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '')}/>
                                        </React.Fragment>
                                    </div>
                                </Tab>
                                <Tab eventKey="notesAndAttachments" title="Notes & Attachments">
                                    <div className='selectContainer'>
                                        <div className='flex'>
                                            <div className="rotatedText">
                                                    Notes
                                                </div>
                                            <div style={{ width: '100%', margin: '12px 0px' }}>
                                                <Row>
                                                    <AddNotes saveRoleAccess={() => true} disableAddBtn={false} disableSaveBtn={!projectRequestStep2DataStore.isTariffNoteAdded} disableDeleteBtn={false} isNoteSaved={projectRequestStep2DataStore.isTariffNoteSaved} onAddNewNoteCallback={() => this.onAddNewNote()} onSaveNotesCallback={() => this.onSaveNotes()} onDeleteNoteCallback={(data) => this.onDeleteNote(data.workflowNoteID)} agGridService={projectRequestStep2DataStore.agGridService} notesGridData={projectRequestStep2DataStore.notesGridData} cellValueChangeMap={projectRequestStep2DataStore.cellValueChangeMap} updateRow={(rowData) => projectRequestStep2DataStore.updateRow(rowData)} ></AddNotes>
                                                </Row>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className='flex'>
                                                <div className="rotatedText">
                                                    Attachments
                                                </div>
                                                <div style={{ width: '100%', margin: '12px 0px' }}>
                                                    <Row>
                                                        <Attachments saveRoleAccess={true} disabled={false} disableDeleteBtn={false} uploadFileCallback={(e) => this.uploadFile(e)} onDownloadFileCallback={(data) => this.downloadAttachment(data.workflowDocumentID)} onUpdateAttachmentCallback={() => this.updateAttachment()} onDeleteFileCallback={(data) => this.deleteAttachment(data.workflowDocumentID)} agGridService={projectRequestStep2DataStore.agGridService} fileUploadGridData={projectRequestStep2DataStore.fileUploadGridData}  cellValueChangeMap={projectRequestStep2DataStore.cellValueChangeMapAttachment} updateRow={(rowData) => projectRequestStep2DataStore.updateRowAttachment(rowData)}></Attachments>
                                                    </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        )}
                        {!this.showOnlyUS() && (
                            <Tabs
                                defaultActiveKey="review"
                                className="screenHeaderTabs"
                            >
                                <Tab eventKey="review" title="Review">
                                    <div className='selectContainer'>
                                        <div style={{ backgroundColor: '#eeeeee', border: '#333333', marginTop: '10px', padding: '5px 5px 5px 30px' }}>
                                            <h6>Review</h6>
                                        </div>
                                        <div style={{ padding: '5px 5px 5px 10px' }}>
                                            <Row style={{ 'marginTop': '0px' }}>
                                                {projectRequestStep2DataStore.selectedTariffs.length > 0 && projectRequestStep2DataStore.reviewTariffDetails.length > 0 &&
                                                    <Tabs
                                                       defaultActiveKey={projectRequestStep2DataStore.reviewTariffDetails[0].tariffNumber}
                                                        className="screenHeaderTabs"
                                                        style={{ 'marginBottom': '20px' }}
                                                        onSelect={(e)=>this.getTariffDetail(e)}
                                                    >
                                                        {projectRequestStep2DataStore.reviewTariffDetails.length > 0 && projectRequestStep2DataStore.reviewTariffDetails.map((item, index) => (
                                                           
                                                            <Tab eventKey={index == 0 ? projectRequestStep2DataStore.reviewTariffDetails[0].tariffNumber : item.tariffNumber} title={item.tariffNumber}>
                                                                
                                                                <div className='reviewTabSelectContainer'>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style_review_header">Tariff Details:</label>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_review_header">Prior Fees:</label>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style_review_header">{item.hasParent ? 'Updated Fees:' : 'Fees'}</label>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Tariff Number:</label>
                                                                            <span className="tariffValue">{item.tariffNumber}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Primary Pipeline Tariff:</label>
                                                                            <span className="tariffValue">{item.primaryPipelineTariffParent.toFixed(2)}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.primaryPipelineTariffChangePercent && Number(item.primaryPipelineTariffChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Primary Pipeline Tariff:</label>
                                                                            <span className={ item.hasParent && item.primaryPipelineTariffChangePercent && Number(item.primaryPipelineTariffChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.primaryPipelineTariff.toFixed(2)} { item.hasParent && item.primaryPipelineTariffChangePercent && Number(item.primaryPipelineTariffChangePercent) !== 0 ? item.primaryPipelineTariffChangePercent === 'New' ? "(" + item.primaryPipelineTariffChangePercent + ")" : "(" + item.primaryPipelineTariffChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Asset Group:</label>
                                                                            <span className="tariffValue">{item.assetGroup}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Secondary Pipeline Tariff:</label>
                                                                            <span className="tariffValue">{item.secondaryRateParent?item.secondaryRateParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.secondaryRateChangePercent && Number(item.secondaryRateChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Secondary Pipeline Tariff:</label>
                                                                            <span className={ item.hasParent && item.secondaryRateChangePercent && Number(item.secondaryRateChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.secondaryRate.toFixed(2)} { item.hasParent && item.secondaryRateChangePercent && Number(item.secondaryRateChangePercent) !== 0  ? item.secondaryRateChangePercent === 'New' ? "(" + item.secondaryRateChangePercent + ")" : "(" + item.secondaryRateChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Start Date:</label>
                                                                            <span className="tariffValue">{item.startDate ? moment(item.startDate).format(AppConstant.DATE_FORMAT) : item.startDate}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Surcharge:</label>
                                                                            <span className="tariffValue">{item.surchargeParent?item.surchargeParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.surchargeChangePercent && Number(item.surchargeChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Surcharge:</label>
                                                                            <span className={ item.hasParent && item.surchargeChangePercent && Number(item.surchargeChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.surcharge.toFixed(2)} { item.hasParent && item.surchargeChangePercent && Number(item.surchargeChangePercent) !== 0 ? item.surchargeChangePercent === 'New' ? "(" + item.surchargeChangePercent + ")" : "(" + item.surchargeChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">End Date:</label>
                                                                            <span className="tariffValue">{item.endtDate ? moment(item.endtDate).format(AppConstant.DATE_FORMAT) : item.endtDate}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">LMCI Abandonment S/C:</label>
                                                                            <span className="tariffValue">{item.lmciAbandonmentSCParent?item.lmciAbandonmentSCParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.lmciAbandonmentSCChangePercent && Number(item.lmciAbandonmentSCChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>LMCI Abandonment S/C:</label>
                                                                            <span className={ item.hasParent && item.lmciAbandonmentSCChangePercent && Number(item.lmciAbandonmentSCChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.lmciAbandonmentSC.toFixed(2)} { item.hasParent && item.lmciAbandonmentSCChangePercent && Number(item.lmciAbandonmentSCChangePercent) !== 0 ? item.lmciAbandonmentSCChangePercent === 'New' ? "(" + item.lmciAbandonmentSCChangePercent + ")" : "(" + item.lmciAbandonmentSCChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Pipeline System:</label>
                                                                            <span className="tariffValue">{item.pipelineSystem}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Offload/Injection Fee:</label>
                                                                            <span className="tariffValue">{item.offloadInjectionFeeParent?item.offloadInjectionFeeParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.offloadInjectionFeeChangePercent && Number(item.offloadInjectionFeeChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Offload/Injection Fee:</label>
                                                                            <span className={ item.hasParent && item.offloadInjectionFeeChangePercent && Number(item.offloadInjectionFeeChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.offloadInjectionFee.toFixed(2)} { item.hasParent && item.offloadInjectionFeeChangePercent && Number(item.offloadInjectionFeeChangePercent) !== 0 ? item.offloadInjectionFeeChangePercent === 'New' ? "(" + item.offloadInjectionFeeChangePercent + ")" : "(" + item.offloadInjectionFeeChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Pipeline Region:</label>
                                                                            <span className="tariffValue">{item.pipelineRegion}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Receipt Terminaling:</label>
                                                                            <span className="tariffValue">{item.receiptTerminallingParent?item.receiptTerminallingParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.receiptTerminallingChangePercent && Number(item.receiptTerminallingChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Receipt Terminaling:</label>
                                                                            <span className={ item.hasParent && item.receiptTerminallingChangePercent && Number(item.receiptTerminallingChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.receiptTerminalling.toFixed(2)} { item.hasParent && item.receiptTerminallingChangePercent && Number(item.receiptTerminallingChangePercent) !== 0 ? item.receiptTerminallingChangePercent === 'New' ? "(" + item.receiptTerminallingChangePercent + ")" : "(" + item.receiptTerminallingChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Operator:</label>
                                                                            <span className="tariffValue">{item.operator}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Quality Management Fee:</label>
                                                                            <span className="tariffValue">{item.qualityManagementFeeParent?item.qualityManagementFeeParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.qualityManagementFeeChangePercent && Number(item.qualityManagementFeeChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Quality Management Fee:</label>
                                                                            <span className={ item.hasParent && item.qualityManagementFeeChangePercent && Number(item.qualityManagementFeeChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.qualityManagementFee.toFixed(2)} { item.hasParent && item.qualityManagementFeeChangePercent && Number(item.qualityManagementFeeChangePercent) !== 0 ? item.qualityManagementFeeChangePercent === 'New' ? "(" + item.qualityManagementFeeChangePercent + ")" : "(" + item.qualityManagementFeeChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Description:</label>
                                                                            <span className="tariffValue">{item.additionalDesc}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Electronic Reporting Fee:</label>
                                                                            <span className="tariffValue">{item.electronicReportingFeeParent?item.electronicReportingFeeParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.electronicReportingFeeChangePercent && Number(item.electronicReportingFeeChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Electronic Reporting Fee:</label>
                                                                            <span className={ item.hasParent && item.electronicReportingFeeChangePercent && Number(item.electronicReportingFeeChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.electronicReportingFee.toFixed(2)} { item.hasParent && item.electronicReportingFeeChangePercent && Number(item.electronicReportingFeeChangePercent) !== 0 ? item.electronicReportingFeeChangePercent === 'New' ? "(" + item.electronicReportingFeeChangePercent + ")" : "(" + item.electronicReportingFeeChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Facility ID:</label>
                                                                            <span className="tariffValue">{item.facilityID}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Total:</label>
                                                                            <span className="tariffValue">{item.totalParent?item.totalParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.totalChangePercent && Number(item.totalChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Total:</label>
                                                                            <span className={ item.hasParent && item.totalChangePercent && Number(item.totalChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.total.toFixed(2)} { item.hasParent && item.totalChangePercent && Number(item.totalChangePercent) !== 0 ? item.totalChangePercent === 'New' ? "(" + item.totalChangePercent + ")" : "(" + item.totalChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">LSD:</label>
                                                                            <span className="tariffValue">{item.lsd}</span>
                                                                        </Col>
                                                                       
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Destination:</label>
                                                                            <span className="tariffValue">{item.destination}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}></Col>
                                                                        <Col className='tariffReview'></Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Tariff Application:</label>
                                                                            <span className="tariffValue">{item.tariffApplication}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Delivery Terminaling:</label>
                                                                            <span className="tariffValue">{item.deliveryTerminallingParent?item.deliveryTerminallingParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.deliveryTerminallingChangePercent && Number(item.deliveryTerminallingChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Delivery Terminaling:</label>
                                                                            <span className={ item.hasParent && item.deliveryTerminallingChangePercent && Number(item.deliveryTerminallingChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.deliveryTerminalling.toFixed(2)} { item.hasParent && item.deliveryTerminallingChangePercent && Number(item.deliveryTerminallingChangePercent) !== 0 ? item.deliveryTerminallingChangePercent === 'New' ? "(" + item.deliveryTerminallingChangePercent + ")" : "(" + item.deliveryTerminallingChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Distribution:</label>
                                                                            <span className="tariffValue">{item.distribution}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Loss Allowance (% Stream):</label>
                                                                            <span className="tariffValue">{item.lossAllowanceTextParent}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.lossAllowanceTextParent !== item.lossAllowanceText ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Loss Allowance (% Stream):</label>
                                                                            <span className={ item.hasParent && item.lossAllowanceTextParent !== item.lossAllowanceText ? "tariffValueChanged" : "tariffValue"}>{item.lossAllowanceText} { item.lossAllowanceTextChangePercent == 'New' ? '(New)' : item.hasParent && item.lossAllowanceTextParent !== item.lossAllowanceText ? '(Changed)' : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">External System:</label>
                                                                            <span className="tariffValue">{item.externalSystem}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Shrinkage (% Stream):</label>
                                                                            <span className="tariffValue">{item.shrinkageTextParent}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.shrinkageTextParent !== item.shrinkageText ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Shrinkage (% Stream):</label>
                                                                            <span className={ item.hasParent && item.shrinkageTextParent !== item.shrinkageText ? "tariffValueChanged" : "tariffValue"}>{item.shrinkageText} { item.shrinkageTextChangePercent == 'New' ? '(New)' : item.hasParent && item.shrinkageTextParent !== item.shrinkageText ? '(Changed)' : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Product Type:</label>
                                                                            <span className="tariffValue">{item.productType}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">BS&W Penalty Fee:</label>
                                                                            <span className="tariffValue">{item.bswPenaltyParent?item.bswPenaltyParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.bswPenaltyChangePercent && Number(item.bswPenaltyChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>BS&W Penalty Fee:</label>
                                                                            <span className={ item.hasParent && item.bswPenaltyChangePercent && Number(item.bswPenaltyChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.bswPenalty.toFixed(2)} { item.hasParent && item.bswPenaltyChangePercent && Number(item.bswPenaltyChangePercent) !== 0 ? item.bswPenaltyChangePercent === 'New' ? "(" + item.bswPenaltyChangePercent + ")" : "(" + item.bswPenaltyChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Product:</label>
                                                                            <span className="tariffValue">{item.product}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Intra System Transfer Fee:</label>
                                                                            <span className="tariffValue">{item.intraSystemTransferFeeParent?item.intraSystemTransferFeeParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.intraSystemTransferFeeChangePercent && Number(item.intraSystemTransferFeeChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Intra System Transfer Fee:</label>
                                                                            <span className={ item.hasParent && item.intraSystemTransferFeeChangePercent && Number(item.intraSystemTransferFeeChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.intraSystemTransferFee.toFixed(2)} { item.hasParent && item.intraSystemTransferFeeChangePercent && Number(item.intraSystemTransferFeeChangePercent) !== 0 ? item.intraSystemTransferFeeChangePercent === 'New' ? "(" + item.intraSystemTransferFeeChangePercent + ")" : "(" + item.intraSystemTransferFeeChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Unit:</label>
                                                                            <span className="tariffValue">{item.unit}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}>
                                                                            <label className="standard_label_style_tariff">Lump Sum Fee:</label>
                                                                            <span className="tariffValue">{item.lumpSumFeeParent?item.lumpSumFeeParent.toFixed(2):0.00}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview'>
                                                                            <label className={ item.hasParent && item.lumpSumFeeChangePercent && Number(item.lumpSumFeeChangePercent) !== 0 ? "standard_label_style_tariff_changed" : "standard_label_style_tariff"}>Lump Sum Fee:</label>
                                                                            <span className={ item.hasParent && item.lumpSumFeeChangePercent && Number(item.lumpSumFeeChangePercent) !== 0 ? "tariffValueChanged" : "tariffValue"}>{item.lumpSumFee.toFixed(2)} { item.hasParent && item.lumpSumFeeChangePercent && Number(item.lumpSumFeeChangePercent) !== 0 ? item.lumpSumFeeChangePercent === 'New' ? "(" + item.lumpSumFeeChangePercent + ")" : "(" + item.lumpSumFeeChangePercent + "%)" : ''}</span>
                                                                        </Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Currency</label>
                                                                            <span className="tariffValue">{item.currency}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}></Col>
                                                                        <Col className='tariffReview'></Col>
                                                                    </Row>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style">Regulator:</label>
                                                                            <span className="tariffValue">{item.regulator}</span>
                                                                        </Col>
                                                                        <Col className='tariffReview' style={item.hasParent ? {display:'block'} : {display:'none'}}></Col>
                                                                        <Col className='tariffReview'></Col>
                                                                    </Row>
                                                                </div>
                                                                <div className='reviewTabSelectContainer'>
                                                                    <Row>
                                                                        <Col className='tariffReview'>
                                                                            <label className="standard_label_style_review_header">Notes & Attachments</label>
                                                                        </Col>
                                                                        </Row>
                                        <div className='flex'>
                                                <div className="rotatedText">
                                                    Notes 
                                                </div>
                                                <div style={{ width: '100%', margin: '12px 0px' }}>
                                                <Row>
                                                    <AddNotes saveRoleAccess={() => projectRequestStep2DataStore.isCellEditable()} disableAddBtn={false} disableSaveBtn={!projectRequestStep2DataStore.isPMCTariffNoteAdded} disableDeleteBtn={false} isNoteSaved={projectRequestStep2DataStore.isPMCTariffNoteSaved} onAddNewNoteCallback={() => this.onPMCAddNewNote(item)} onSaveNotesCallback={() => this.onPMCSaveNotes()} onDeleteNoteCallback={(data) => this.onPMCDeleteNote(data.pmcTariffNoteID)} agGridService={projectRequestStep2DataStore.agGridService} notesGridData={projectRequestStep2DataStore.notesPMCGridData.length===0 ? item.pmcNotes===null?[]:item.pmcNotes:projectRequestStep2DataStore.notesPMCGridData} cellValueChangeMap={projectRequestStep2DataStore.cellValuePMCChangeMap} updateRow={(rowData) => projectRequestStep2DataStore.updatePMCRow(rowData)} ></AddNotes>
                                                </Row>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className='flex'>
                                            <div className="rotatedText">
                                                Attachments 
                                            </div>
                                            <div style={{ width: '100%', margin: '12px 0px' }}>
                                                <Row>
                                                <Attachments saveRoleAccess={projectRequestStep2DataStore.isCellEditable()} disabled={false} disableDeleteBtn={false} uploadFileCallback={(e) => this.uploadPMCFile(e, item)} onDownloadFileCallback={(data) => this.downloadPMCAttachment(data.pmcTariffDocumentID)} onUpdateAttachmentCallback={() => this.updatePMCAttachment()} onDeleteFileCallback={(data) => this.deletePMCAttachment(data.pmcTariffDocumentID)} agGridService={projectRequestStep2DataStore.agGridService} fileUploadGridData={projectRequestStep2DataStore.fileUploadPMCGridData.length===0 ? item.pmcDocs===null?[]:item.pmcDocs:projectRequestStep2DataStore.fileUploadPMCGridData}  cellValueChangeMap={projectRequestStep2DataStore.cellValueChangeMapPMCAttachment} updateRow={(rowData) => projectRequestStep2DataStore.updatePMCRowAttachment(rowData)}></Attachments>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>


                                                            </Tab>
                                                        ))}
                                                    </Tabs>
                                                }
                                            </Row>
                                        </div>
                                    </div>
                                </Tab>
                                <Tab eventKey="notesAndAttachments" title="Notes & Attachments">
                                    <div className='selectContainer'>
                                        <div className='flex'>
                                                <div className="rotatedText">
                                                    Notes
                                                </div>
                                                <div style={{ width: '100%', margin: '12px 0px' }}>
                                                    <Row>
                                                        <AddNotes saveRoleAccess={() => true} disableAddBtn={false} disableSaveBtn={!projectRequestStep2DataStore.isTariffNoteAdded} disableDeleteBtn={false} isNoteSaved={projectRequestStep2DataStore.isTariffNoteSaved} onAddNewNoteCallback={() => this.onAddNewNote()} onSaveNotesCallback={() => this.onSaveNotes()} onDeleteNoteCallback={(data) => this.onDeleteNote(data.workflowNoteID)} agGridService={projectRequestStep2DataStore.agGridService} notesGridData={projectRequestStep2DataStore.notesGridData} cellValueChangeMap={projectRequestStep2DataStore.cellValueChangeMap} updateRow={(rowData) => projectRequestStep2DataStore.updateRow(rowData)} ></AddNotes>
                                                    </Row>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className='flex'>
                                            <div className="rotatedText">
                                                Attachments
                                            </div>
                                            <div style={{ width: '100%', margin: '12px 0px' }}>
                                                <Row>
                                                    <Attachments saveRoleAccess={true} disabled={false} disableDeleteBtn={false} uploadFileCallback={(e) => this.uploadFile(e)} onDownloadFileCallback={(data) => this.downloadAttachment(data.workflowDocumentID)} onUpdateAttachmentCallback={() => this.updateAttachment()} onDeleteFileCallback={(data) => this.deleteAttachment(data.workflowDocumentID)} agGridService={projectRequestStep2DataStore.agGridService} fileUploadGridData={projectRequestStep2DataStore.fileUploadGridData}  cellValueChangeMap={projectRequestStep2DataStore.cellValueChangeMapAttachment} updateRow={(rowData) => projectRequestStep2DataStore.updateRowAttachment(rowData)}></Attachments>
                                                </Row>
                                            </div>
                                        </div>
                                    </div>
                                </Tab>
                            </Tabs>
                        )}
                        <div>
                            <div className='screenHeader'>
                            <h2 className='screen_title'> Workflow Details</h2>
                        </div>
                        <div className='selectContainer'>
                                <Row>
                                    <div className="rightFlex">
                                        <div className="leftFlex">
                                            <label className="standard_label_style">{projectRequestStep2DataStore.statusOrHistory?'Workflow Status':'Workflow History'}</label>
                                        </div>
                                        <div className="rightFlexInner">
                                            <CustomButton type={CustomButtonType.Submit} disabled={projectRequestStep2DataStore.statusOrHistory} onClick={()=>{projectRequestStep2DataStore.getWorkflowStatusGridData()}}>
                                            Workflow Status
                                            </CustomButton>
                                            <CustomButton type={CustomButtonType.Submit} disabled={!projectRequestStep2DataStore.statusOrHistory} onClick={()=>{projectRequestStep2DataStore.getWorkflowHistoryStatusGridData()}}>
                                            Workflow History
                                            </CustomButton>
                                        </div>
                                    </div>
                                </Row>
                                <div className="flex">
                                <div className="innerflexwidths">
                                    <Row>
                                        <AgGridComponent gridConfig={this.getGridConfig('Step 2')}/>
                                    </Row>
                                </div>
                                <div className="innerflexwidths">
                                    <div className="flexColumn">
                                        <div style={{'width':'100%'}}>
                                            <Row style={{'marginTop': '0px'}}>
                                                <Col style={{'marginTop': '10px'}}>
                                                    <label className="standard_label_style">Step Description:</label>
                                                </Col>
                                            </Row>
                                            <Row style={{'marginTop': '0px'}}>
                                                <Col style={{'marginTop': '5px'}}>
                                                <label style={{'fontSize': '14px'}}>{projectRequestStep2DataStore.stepDescription&&projectRequestStep2DataStore.stepDescription||"Tariff setup step consists of creating Base Tariff & creating Tariff Log. It also consists of linking the base tariff and tariff log to the workflow. This step is performed by the Tariff department."}</label>
                                                </Col>
                                            </Row>
                                            <Row style={{'marginTop': '0px'}}>
                                                <Col style={{'marginTop': '10px'}} >
                                                    <label className="standard_label_style">Comment*:</label>
                                                    <textarea
                                                    style={{marginLeft: '20px', borderRadius: '5px', border: '1px solid #a3d0e4'}} 
                                                    value={projectRequestStep2DataStore.formData.comment}
                                                    onChange={(e) => projectRequestStep2DataStore.changeFormData(e.target.value, "comment")}
                                                    disabled={projectRequestStep2DataStore.workflowStepStatus==null}
                                                    />
                                                </Col>
                                            </Row>
                                            </div>
                                            {this.showOnlyUS() && (
                                                <div className="rightFlexButtons">

                                                   {!(!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '') && (<>{(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options'||projectRequestStep2DataStore.href==='/project-request-infrastructure'||projectRequestStep2DataStore.href==='/project-request-step-2') && (
                                                        // <CustomButton type={CustomButtonType.Submit} onClick={()=>{this.goToPreviousPage()}}>
                                                        //     &#9664; Go Back
                                                        // </CustomButton>
                                                        <></>
                                                        ) 
                                                        ||
                                                        (<CustomButton type={CustomButtonType.Submit} disabled={(!this.checkRoleAccessSaveCompleteSchedulingApproval()&&projectRequestStep2DataStore.workflowstepName==='Complete Scheduling Approval')||(!this.checkRoleAccessSaveCompleteCommercialApproval()&&projectRequestStep2DataStore.workflowstepName==='Complete Commercial Approval')||(!this.checkRoleAccessSaveCompletePLAccountingApproval()&&projectRequestStep2DataStore.workflowstepName==='Complete PL Accounting Approval')||(!this.checkRoleAccessSaveCompleteTariffApproval()&&projectRequestStep2DataStore.workflowstepName==='Complete Tariff Approval')||(!this.checkRoleAccessSaveCompleteTariffReview()&&projectRequestStep2DataStore.workflowstepName==='Complete Tariff Review')||(!this.checkRoleAccessSave()&&projectRequestStep2DataStore.workflowstepRole==='Infrastructure Setup')||(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options'&&Array.isArray(projectRequestStep2DataStore.tariffOptions) && projectRequestStep2DataStore.tariffOptions.length===0)} onClick={()=>{projectRequestStep2DataStore.saveComments('reject')}}>
                                                        &#9664; Reject
                                                        </CustomButton>) 
                                                    }</>)}
                                                    <CustomButton type={CustomButtonType.Submit} onClick={()=>{history.push('/ProjectDashboard')}}>
                                                        Cancel
                                                    </CustomButton>
                                                    {!(!projectRequestStep2DataStore.workflowstepName || projectRequestStep2DataStore.workflowstepName === '') &&
                                                    <CustomButton type={CustomButtonType.Submit} disabled={(!this.checkRoleAccessSaveCompleteSchedulingApproval()&&projectRequestStep2DataStore.workflowstepName==='Complete Scheduling Approval')||(!this.checkRoleAccessSaveCompleteCommercialApproval()&&projectRequestStep2DataStore.workflowstepName==='Complete Commercial Approval')||(!this.checkRoleAccessSaveCompletePLAccountingApproval()&&projectRequestStep2DataStore.workflowstepName==='Complete PL Accounting Approval')||(!this.checkRoleAccessSaveCompleteTariffApproval()&&projectRequestStep2DataStore.workflowstepName==='Complete Tariff Approval')||(!this.checkRoleAccessSaveCompleteTariffReview()&&projectRequestStep2DataStore.workflowstepName==='Complete Tariff Review')||(!this.checkRoleAccessSave()&&projectRequestStep2DataStore.workflowstepRole==='Infrastructure Setup')||(projectRequestStep2DataStore.href==='/project-request-clone-tariff-options'&&Array.isArray(projectRequestStep2DataStore.tariffOptions) && projectRequestStep2DataStore.tariffOptions.length===0) || this.isforwardWorflowStepDsabled()} onClick={()=>{projectRequestStep2DataStore.saveComments()}}>
                                                        <>{projectRequestStep2DataStore.forwardButtonText} &#9654; </>  
                                                    </CustomButton>}
                                                </div>
                                            )}
                                            {!this.showOnlyUS() && projectRequestStep2DataStore.workflowstepName==='Approve & Export' && (
                                                <div className="rightFlexButtons">
                                                    {/* <CustomButton type={CustomButtonType.Submit} onClick={() => { projectRequestStep2DataStore.rejectTariffRequest() }} disabled= {_.intersection(['PMCEasternNGLApproval', 'PMCWesternNGLApproval', 'PMCCrudeAssetsApproval'], accountStore.userRoles).length == 0}>
                                                        Reject
                                                    </CustomButton>
                                                    <CustomButton type={CustomButtonType.Submit} onClick={() => { projectRequestStep2DataStore.approveTariffRequest() }} disabled= {_.intersection(['PMCEasternNGLApproval', 'PMCWesternNGLApproval', 'PMCCrudeAssetsApproval'], accountStore.userRoles).length == 0}>
                                                        Approve/Export &#9654;
                                                    </CustomButton> */}

                                                    <CustomButton type={CustomButtonType.Submit} onClick={() => { projectRequestStep2DataStore.rejectTariffRequest() }} disabled= {projectRequestStep2DataStore.isButtonEnabled()}>
                                                        Reject
                                                    </CustomButton>
                                                    <CustomButton type={CustomButtonType.Submit} onClick={() => { projectRequestStep2DataStore.approveTariffRequest() }} disabled= {projectRequestStep2DataStore.isButtonEnabled()}>
                                                        Approve/Export &#9654;
                                                    </CustomButton>

                                                </div>
                                            )}
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    {this.props.projectRequestStep2DataStore.showLoader == true &&
                        <div className = 'alert-overlay'> 
                        <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                        </div> 
                    }
                    </div>
                </Container>
            </div>
    );
}}
