import * as React from 'react';
import './_header-component.scss';
import logo from '../../../../assets/cts-logo.png';
import { NavLink, withRouter, RouteComponentProps, Link} from 'react-router-dom';
import { Navbar, Nav, Dropdown, NavDropdown, Container, Col, Row } from 'react-bootstrap';
import CustomToggle from './custom-toggle';
import moment from 'moment';
import { localStorageService } from 'shared/services/local-storage-service';
// import { AppConstant } from 'app_constant';
import { AuthToken } from 'shared/types/shared';
import { restApiService } from 'shared/services/rest-api-service';
import { dataStore } from 'common/stores';
import { accountStore } from 'features/account/stores';
import { AppConstant } from 'app_constants';
import history from './history';
import { logEntryDataStore } from 'features/tariff-management/tariff-log/stores';
import { LogEntryAndScreenMapping, TariffLogEntry } from 'features/tariff-management/tariff-log/constant/enums';

export interface HeaderComponentProps extends RouteComponentProps {
  onLogout?: () => void;
  roles?: string[];
  userName?: string;
}

interface HeaderComponentState {
  expanded: boolean;
  count: number;
}

class HeaderComponent extends React.Component<HeaderComponentProps, HeaderComponentState> {
  count;
  constructor(props) {
    super(props);
    this.state = {
      expanded: false,
      count: 0,
    };
    setInterval(async () => {
      const token: AuthToken | undefined = localStorageService.get(AppConstant.AUTHTOKEN);
      if (!(token && token.value && moment().toISOString() < token.expiry)) {
        await restApiService.getTokenValue();
      }
    }, 3000000);

    const roleData = accountStore.getRoleData();
    const userRoleNames = (roleData.map(x => x.roleName)).filter((x, index, arr) => arr.indexOf(x) === index);
    accountStore.setUserRole(userRoleNames);
    accountStore.setRoleAccessData(roleData);
  }

  handleToggle = () => {
    this.setState({
      expanded: !this.state.expanded
    });
  };

  handleSelect = () => {
    this.setState({ expanded: false });
  };

  public render(): React.ReactNode {
    return (
      <Container fluid={true}>
        <Row>
          <Col>
            <Navbar onToggle={this.handleToggle} expanded={this.state.expanded} expand="sm" className="header__plain">
              {this.renderLogo()}
              <Navbar.Brand className="header__brand">Centralized Tariff System</Navbar.Brand>
              {this.renderProfile()}
            </Navbar>
            <Navbar onToggle={this.handleToggle} expanded={this.state.expanded} expand="sm" className="header">
              <Navbar.Toggle aria-controls="responsive-navbar-nav" />
              <Navbar.Collapse id="responsive-navbar-nav">
                {this.renderNavigation()}
                {this.renderNavigationProjectMgmtDataDropDown()}
                {this.renderNavigationTariffMgmtDropDown()}
                {this.renderNavigationMasterDataDropDown()}
                {accountStore.defaultUserLocation === 'CAN' && this.renderNavigationReportsDropDown()}
              </Navbar.Collapse>
            </Navbar>
          </Col>
        </Row>
      </Container>
              
               
    );
  }
  

  renderLogo(): React.ReactNode {
    const to  = accountStore.defaultUserLocation === 'CAN' ? '/PMCDashboard' : '/Dashboard';
    return (
      <div className="header__logo">
        <NavLink to={to}>
          <img className="header-logo" src={logo} alt="Logo" />
        </NavLink>
      </div>
    );
  }

  renderProfile(): React.ReactNode {
    return (
      <div className="header__user-info">
        <div className="header__user-text">Welcome, {this.props.userName}</div>
        {this.renderData()}
      </div>
    );
  }

  renderData(): React.ReactNode {
    return (
      <Dropdown>
        <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
          <div className="header__user-img">
            <div className="header__user-img-text">{this.props.userName ? this.props.userName.charAt(0) : 'A'}</div>
          </div>
        </Dropdown.Toggle>
        <Dropdown.Menu className="dropdown-menu-end">
          <Dropdown.Item>
            <div className="header__menu" onClick={this.props.onLogout}>
              Logout
            </div>
          </Dropdown.Item>
          <Dropdown.Item>
            <div className="header__menu">{this.getBuildInfo('1.0.0')} ({this.getDeployDate(new Date())})</div>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    );
  }

  getUserRole(roleRequires: string[], rolesAssigned: string[]): string {
    const isRolePresent = roleRequires.some(role => rolesAssigned.includes(role));
    return isRolePresent ? rolesAssigned[0] : '';
  }


  getBuildInfo(buildInfo) {

    return buildInfo;
  }

  getDeployDate(deployDate) {
    const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const formatdata = moment(deployDate, 'DD/MM/YYYY');
    const date = formatdata.toDate();
    return monthNames[date.getMonth()] + ' ' + date.getDate() + ', ' + date.getFullYear();
  }

  renderLogEntryScreens(tariffLogEntry) {
    const pathNames: any[] = [];
    (Object.keys(LogEntryAndScreenMapping) as (keyof typeof LogEntryAndScreenMapping)[]).map(
      (key, index) => {
        console.log(key);
        if(LogEntryAndScreenMapping[key] === history.location.pathname && tariffLogEntry !== TariffLogEntry[key] ) {
          pathNames.push(LogEntryAndScreenMapping[key]);
        }
      },
    ); 
    if(pathNames.length >  0) {
      logEntryDataStore.selectedLogEntryType = tariffLogEntry; 
      logEntryDataStore.resetData();
      logEntryDataStore.getWorkflowInstanceData();
      logEntryDataStore.getProjectNames();
    } else if(logEntryDataStore.tariffLogId) {
      logEntryDataStore.selectedLogEntryType = tariffLogEntry;
      logEntryDataStore.createNewIndexLogEntry();
      logEntryDataStore.getProjectNames();
    }
  }

  renderNavigation(): React.ReactNode {
    // const userInfo = accountStore.getUserInfo();
    const to  = accountStore.defaultUserLocation === 'CAN' ? '/PMCDashboard' : '/Dashboard';
    return (
      // <Nav className="ml-3 d-none d-sm-block border">
      <div className='header__navItem'>
        {dataStore.checkOperationAccessWithModule('view', 'Dashboard Home') && (
           
          <NavLink className="header__link header__firstlinks" exact activeClassName="header-active" to={to}>
            Home
          </NavLink>
        )}
      
      </div>
    );
  }

  renderNavigationProjectMgmtDataDropDown(): React.ReactNode {
    return (
      <div className='header__navItem'>
        <NavLink className="header__dropdown header__firstlinks" activeClassName="header-active" to={history.location.pathname} isActive={() => ['/ProjectDashboard', '/ProjectRequest'].includes(history.location.pathname)} >
          <NavDropdown title="Project Management" id="basic-nav-dropdown">
            {dataStore.checkOperationAccessWithModule('view', 'Project Dashboard') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/ProjectDashboard">Project Dashboard</NavDropdown.Item>
            )
            }
              {dataStore.checkOperationAccessWithModule('view', 'Project Request') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/ProjectRequest">
              Project Request
          </NavDropdown.Item>
             )}
          </NavDropdown>
          
        </NavLink>
      </div>
    );
  }

  renderNavigationReportsDropDown(): React.ReactNode {
    return (
      <div className='header__navItem'>
        <NavLink className="header__dropdown header__firstlinks" activeClassName="header-active" to='' >
          <NavDropdown title="Reports" id="basic-nav-dropdown">
        {
          <NavDropdown.Item onClick={()=> {window.open('https://app.powerbi.com/groups/f9e3a33a-945c-4fc8-9572-f6885388dca8/list?experience=power-bi%22','_blank','noreferrer')}}> Reports</NavDropdown.Item>          
        }
          </NavDropdown>
        </NavLink>
      </div>
    );
  }

  renderNavigationMasterDataDropDown(): React.ReactNode {
    return (
      <div className='header__navItem'>
        <NavLink className="header__dropdown header__firstlinks" activeClassName="header-active" to={history.location.pathname} isActive={() => ['/MasterDataManagement', '/Miscellaneous', '/Agency', '/PipelineMaintenance', '/LocationMaintenance'].includes(history.location.pathname)} >
          <NavDropdown title="Reference Data Mgmt" id="basic-nav-dropdown">
            {dataStore.checkOperationAccessWithModule('view', 'Setting Maintenance') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/Miscellaneous">Setting Maintenance</NavDropdown.Item>)}

            {dataStore.checkOperationAccessWithModule('view', 'Agency Maintenance') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/Agency">
                Agency Maintenance
              </NavDropdown.Item>
            )}
            {dataStore.checkOperationAccessWithModule('view', 'Pipeline System Maintenance') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/PipelineMaintenance">
                Pipeline Maintenance
              </NavDropdown.Item>
            )}
            {dataStore.checkOperationAccessWithModule('view', 'Location Maintenance') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/LocationMaintenance">
                Location Maintenance
              </NavDropdown.Item>
            )}
            {dataStore.checkOperationAccessWithModule('view', 'Connection Point Maintenance') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/ConnectionPointMaintenance">
                Connection Point Maintenance
                </NavDropdown.Item>
            )}
            {dataStore.checkOperationAccessWithModule('view', 'Location Maintenance') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/TariffRateTypes">
                Tariff Rate Types
              </NavDropdown.Item>
            )}
            {dataStore.checkOperationAccessWithModule('view', 'Filing Entity Maintenance') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/FilingEntityMaintenance">
                Filing Entity Maintenance
              </NavDropdown.Item>
            )}
             {dataStore.checkOperationAccessWithModule('view', 'Filing Carrier Maintenance') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/FilingCarrierMaintenance">
                Filing Carrier Maintenance
              </NavDropdown.Item>
            )}
             {dataStore.checkOperationAccessWithModule('view', 'Grade Maintenance') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/GradeMaintenance">
                Grade Maintenance
              </NavDropdown.Item>
            )}

            {dataStore.checkOperationAccessWithModule('view', 'Line Segment Maintenance') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/LineSegmentMaintenance">
                Line Segment Maintenance
              </NavDropdown.Item>
            )}

             {dataStore.checkOperationAccessWithModule('view', 'Profit Center Maintenance') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/ProfitCenterMaintenance">
                Profit Center Maintenance
              </NavDropdown.Item>
            )}

            {/* {dataStore.checkOperationAccessWithModule('view', 'Shipper Loss/Gain Configuration') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/ShipperLossGainMaintenance">
                Shipper Loss Gain Maintenance
              </NavDropdown.Item>
            )} */}

           {dataStore.checkOperationAccessWithModule('view', 'Customer Maintenance') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/CustomerMaintenance">
                Customer Maintenance
              </NavDropdown.Item>
            )}

            {dataStore.checkOperationAccessWithModule('view', 'PMC Tariff Management') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/RightAngleXRef">
                Right Angle XRef
              </NavDropdown.Item>
            )}
            {/* <NavDropdown.Item as={Link} to="/AgencyDetails">
                  Agency Details
                </NavDropdown.Item> */}
            {/* <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item href="#action/3.4">
                  Separated link
                </NavDropdown.Item> */}
          </NavDropdown>
        </NavLink>
      </div>
    );
  }

  renderNavigationTariffMgmtDropDown(): React.ReactNode {
    return (
      <div className='header__navItem'>
        <NavLink className="header__dropdown header__firstlinks" activeClassName="header-active" to={history.location.pathname}  isActive={() => ['/BaseTariff', '/TariffLog', '/PMCTariff', '/ProjectRequest', '/SystemFeeMaintenance', 'PMCGridView', '/TariffManagement', '/IndexRateMaintenance'].includes(history.location.pathname)}>
          <NavDropdown title="Tariff Management" id="basic-nav-dropdown">
            {dataStore.checkOperationAccessWithModule('view', 'Tariff Dashboard') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/TariffDashboard">
               Tariff Dashboard
              </NavDropdown.Item>
            )}
            {dataStore.checkOperationAccessWithModule('view', 'Base Tariff') && accountStore.rolesJson[0]?.defaultLocation==='US' && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/BaseTariff">
                Base Tariff
              </NavDropdown.Item>
            )}
            {/* {dataStore.checkOperationAccessWithModule('view', 'Tariff Log') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/TariffLog">
                Tariff Log
              </NavDropdown.Item>
            )} */}
            {dataStore.checkOperationAccessWithModule('view', 'Tariff Log') && (
              // <NavDropdown.Item className="header__dropdownItem">
        
              <NavDropdown title='Tariff Log' drop={'end'} id="basic-nav-dropdown" className= 'header__subDropdown'>
                <NavDropdown.Item className="header__dropdownItem" as={Link} to="/CreateFilingLogEntry" onClick={() => {this.renderLogEntryScreens(TariffLogEntry.TariffFiling)} }>
                  Create Tariff Filing
                </NavDropdown.Item>
                <NavDropdown.Item className="header__dropdownItem" as={Link} to="/CreateIndexLogEntry" onClick={() => {this.renderLogEntryScreens(TariffLogEntry.TariffFilingIndex)}}>
                  Create Filing Index
                </NavDropdown.Item>
                <NavDropdown.Item className="header__dropdownItem" as={Link} to="/CreateMaintenanceLogEntry" onClick={() => {this.renderLogEntryScreens(TariffLogEntry.Maintenance)}}>
                  Create Tariff Maintenance
                </NavDropdown.Item>
                <NavDropdown.Item className="header__dropdownItem" as={Link} to="/CreateRequestLogEntry" onClick={() => { this.renderLogEntryScreens(TariffLogEntry.Request)}}>
                  Create Tariff Request Test
                </NavDropdown.Item>
              </NavDropdown>
            )}
            
            {dataStore.checkOperationAccessWithModule('view', 'Tariff Option Tariff Reference') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/TariffOptions">
                Tariff Options
              </NavDropdown.Item>
            )}
            {dataStore.checkOperationAccessWithModule('view', 'PMC Tariff Management') &&
              (
                <NavDropdown.Item className="header__dropdownItem" as={Link} to="/PMCTariff">
                  PMC Tariff
                </NavDropdown.Item>
            )}
            {dataStore.checkOperationAccessWithModule('view', 'PMC Tariff Management') &&
              ( 
                <NavDropdown.Item className="header__dropdownItem" as={Link} to="/SystemFeeMaintenance">
                  System Fee
                </NavDropdown.Item>
            )}
            {dataStore.checkOperationAccessWithModule('view', 'PMC Tariff Management') &&
              ( 
                <NavDropdown.Item className="header__dropdownItem" as={Link} to="/PMCGridView">
                  PMC Tariff Update
                </NavDropdown.Item>
            )}
               {/* <NavDropdown.Item className="header__dropdownItem" as={Link} to="/ProjectRequest">
                  Project Request
                </NavDropdown.Item> */}
            {dataStore.checkOperationAccessWithModule('view', 'Index Rate Maintenance') && (
              <NavDropdown.Item className="header__dropdownItem" as={Link} to="/IndexRateMaintenance">
                Index Rate Maintenance
              </NavDropdown.Item>
            )}
          </NavDropdown>
        </NavLink>
      </div>
    );
  }
}


const HeaderRouterComponent = withRouter(HeaderComponent);
export default HeaderRouterComponent;
