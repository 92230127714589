import React, { Component } from 'react';
import { observer, inject } from 'mobx-react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { PMCGridViewDataStore } from '../stores/pmc-grid-view-data-store';
import {
    GridOptions,
} from 'ag-grid-community';
import { AgGridComponent } from '../../../../common/components/ag-grid-component';
import history from '../../../../shared/components/header-component/component/history';
import './pmc-grid-view.scss'
import { CustomButton, CustomButtonType } from '../../../../shared/components/custom-button/custom-button';
import { dataStore } from '../../../../common/stores';
import { pmcGridViewDataStore } from '../stores';
import { ReactDatePicker } from 'common/components/datepicker/react-datepicker';
import DatePicker from 'react-datepicker';
import { ReactSelect } from 'common/components/select/select';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import Accordion from 'react-bootstrap/Accordion';
import { Attachments } from 'common/components/attachments/attachments';
import { AddNotes } from 'common/components/add-notes/add-notes';
import { PMCNotesResponse } from '../model/model';
import moment from 'moment';
import { localStorageService } from 'shared/services/local-storage-service';


/* eslint-disable */
interface PMCGridViewContainerProps {
    pmcGridViewDataStore: PMCGridViewDataStore;
}


interface PMCGridViewContainerState {
    search: string;
    lastTariffNoteID: number;
}

@inject('pmcGridViewDataStore')
@observer
export class PMCGridViewContainer extends Component<PMCGridViewContainerProps, PMCGridViewContainerState> {
    constructor(props: PMCGridViewContainerProps | Readonly<PMCGridViewContainerProps>) {
        super(props);
        this.state = {
            search: '',
            lastTariffNoteID: 0,
        };
        dataStore.setModuleName('PMC Tariff Management')
    }
    
    componentDidMount() {
      const {pmcGridViewDataStore} = this.props;
      history.push('/PMCGridView');
      pmcGridViewDataStore.init();
      pmcGridViewDataStore.agGridService.refreshGrid();
    }

    async componentDidUpdate(){
        const filters = await localStorageService.get('PMC Tariff Management_filter');
        await this.props.pmcGridViewDataStore.agGridService.setAllFilters(filters);
        const columnVisible = localStorageService.get('PMC Tariff Management_' + this.props.pmcGridViewDataStore.accountStore.userName);
        this.props.pmcGridViewDataStore.agGridService.setColumnVisibility(columnVisible);
    }

    onSelectAssetGroup = (e) => {
        pmcGridViewDataStore.setAssetGroup(e);
    };

    onSelectSystemGroup = (e) => {
        pmcGridViewDataStore.setSystemGroup(e);
    };

    onSelectPipelineGroup = (e) => {
        pmcGridViewDataStore.setPipelineGroup(e);
    };

    onSelectDistributionType = (e) => {
        pmcGridViewDataStore.setDistributionType(e);
    };

    onSelectEffectiveStartDate = (e) => {
        pmcGridViewDataStore.setEffectiveStartDate(e);
    };
    
    onUpdateClick = () => {
      pmcGridViewDataStore.onUpdateClick();
    };
    
    onDuplicateBtnClicked = () => {
      pmcGridViewDataStore.onDuplicateBtnClicked();
    };  

    onBulkDuplicateClick = () => {
      pmcGridViewDataStore.isButtonDisabled=false;
      pmcGridViewDataStore.onBulkDuplicateClick();
    };
    
    handleClose = () => {
      pmcGridViewDataStore.isButtonDisabled=false;
      pmcGridViewDataStore.onCloseBulkDuplicateModal();
    }
    
    onBulkDuplicateConfirmationClicked = () => {
      if(pmcGridViewDataStore.isButtonDisabled===false)
      pmcGridViewDataStore.onBulkDuplicateConfirmationClicked();
      pmcGridViewDataStore.isButtonDisabled=true;
    };
    
    handleCloseBulkDuplicateConfirmationModal = () => {
      pmcGridViewDataStore.handleCloseBulkDuplicateConfirmationModal();
    } 

    onSelectEffStartDate = (e) => {
      pmcGridViewDataStore.setEffStartDate(e);
  };

  onSelectEffectiveEndDate = (e) => {
    pmcGridViewDataStore.setEffectiveEndDate(e);
  };

  onAddNewNote() {
    if(pmcGridViewDataStore.isSelectedRow())
      {
    const pmcTariffNoteID = this.state.lastTariffNoteID - 1;
    const rowData = [{
        pmcTariffNoteID: pmcTariffNoteID,
        pmcTariffDetailID: pmcGridViewDataStore.pmcTariffDetailID,
        notes: '',
        createUser: pmcGridViewDataStore.createdUser,
        createDate: moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
        updateUser: null,
        updateDate: null,
        pmcTariffDetail: null,
    } as unknown as PMCNotesResponse, ...pmcGridViewDataStore.notesGridData];
    pmcGridViewDataStore.setPMCTariffNoteGridData(rowData);
    this.setState({ ...this.state, lastTariffNoteID: pmcTariffNoteID });
    pmcGridViewDataStore.notesGridData.length > 0 ? pmcGridViewDataStore.isTariffNoteAdded = true : false;
  }
  
};

onSaveNotes = () => {
  pmcGridViewDataStore.saveTariffNotes();
};

onUpdateRow = (rowData) =>{

}

onDeleteNote = (noteId) => {
  const confirmService = new ConfirmService();
  confirmService.showConfirmDialog(() => {
      pmcGridViewDataStore.deleteTariffNote(noteId);
  },
      'Delete',
      'Are you sure you want to delete the selected note?'
  );
  pmcGridViewDataStore.notesGridData.length > 0 ? pmcGridViewDataStore.isTariffNoteAdded = true : false;
};

uploadFile(e) {
  if(!pmcGridViewDataStore.isSelectedRow())
    {
     e.target.value=''
     e.target.files=[]
    }
    else
    {
       pmcGridViewDataStore.uploadFile(e);
    }
};

downloadAttachment(fileID) {
  pmcGridViewDataStore.downloadAttachment(fileID);
};

deleteAttachment = async (files) => {
  const confirmService = new ConfirmService();
  confirmService.showConfirmDialog(
      () => {
          pmcGridViewDataStore.deleteAttachment(files.id);
      },
      'Delete',
      'Are you sure you want to delete the selected attachment?'
  );
};

updateAttachment = () => {
  pmcGridViewDataStore.updateAttachment();
}

  
    render(): React.ReactNode {
      const { pmcGridViewDataStore } = this.props;
      return (
        <Container fluid>
          {this.renderPMCGridViewDataStoreScreen()}
        </Container>
      );
    }

    renderPMCGridViewDataStoreScreen() : React.ReactNode {
      const { pmcGridViewDataStore } = this.props;
      return (
        <div>
          <Row>
              <Col>
                <div className='pmcGridViewBox'>
                  <div className='screenHeader'>
                    <h2 className='screen_title'>PMC Tariff Update</h2>
                  </div>
                  <div className='selectContainer'>
                    <div className='settingSelectionDiv'>
                      <Row>
                        <Col>
                          <label className='standard_label_style'>Asset Group</label>
                          <ReactSelect
                              className='select'
                              values={pmcGridViewDataStore.selectedAssetGroup}
                              placeholder="Select"
                              options={[{value:'All', label:'All'},...pmcGridViewDataStore.assetGroupDropdownOptions]}
                              onChange={this.onSelectAssetGroup}
                              isMulti={false}
                              isSearchable={true}
                          />
                        </Col>
                        <Col>
                          <label className="standard_label_style">Pipeline System</label>
                          <ReactSelect
                              className='expanded_select'
                              values={pmcGridViewDataStore.selectedSystemGroup}
                              placeholder="Select"
                              options={[{value:'All', label:'All'},...pmcGridViewDataStore.systemGroupDropdownOptions]}
                              onChange={this.onSelectSystemGroup}
                              isMulti={false}
                              isSearchable={true}
                          />
                        </Col>
                        <Col>
                          <label className='standard_label_style'>Pipeline Region</label>
                          <ReactSelect
                              className='expanded_select'
                              values={pmcGridViewDataStore.selectedPipelineGroup}
                              placeholder="Select"
                              options={[{value:'All', label:'All'},...pmcGridViewDataStore.pipelineGroupDropdownOptions]}
                              onChange={this.onSelectPipelineGroup}
                              isMulti={false}
                              isSearchable={true}
                          />
                      </Col>
                      <Col>
                          <label className='standard_label_style distribution'>Distribution</label>
                          <ReactSelect
                              className='select'
                              values={pmcGridViewDataStore.selectedDistributionType}
                              placeholder="Select"
                              options={[{value:'All', label:'All'},...pmcGridViewDataStore.distributionTypeDropdownOptions]}
                              onChange={this.onSelectDistributionType}
                              isMulti={false}
                              isSearchable={true}
                          />
                      </Col>
                      <Col>
                            <label className="standard_label_style">Start Date</label>
                            <DatePicker
                                className='datepicker__holder'
                                selected={pmcGridViewDataStore.selectedEffStartDate}
                                onChange={(e) => {
                                    this.onSelectEffStartDate(e);
                                }}
                                dateFormat="MM/yyyy"
                                showMonthYearPicker
                            />
                        </Col>
                        <Col>
                                <label className="standard_label_style">End Date</label>
                                <DatePicker
                                    className='datepicker__holder'
                                    selected={pmcGridViewDataStore.selectedEffectiveEndDate}
                                    onChange={(e) => {
                                        this.onSelectEffectiveEndDate(e);
                                    }}
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                />
                            </Col>
                        </Row>
                    </div>
                    <div className='searchButtonDiv'>
                      <CustomButton type={CustomButtonType.Submit} onClick={()=> pmcGridViewDataStore.onSearchClick()} disabled= {false}>
                          Search
                      </CustomButton>
                      <CustomButton type={CustomButtonType.Submit} onClick={()=> pmcGridViewDataStore.onClearSearch()} disabled= {false}>
                          Clear
                      </CustomButton>
                    </div>
                  </div>
                {this.props.pmcGridViewDataStore.showLoader == true &&
                  <div className = 'alert-overlay'> 
                    <div style={{margin: 'auto'}} className = 'dots-1'></div> 
                  </div> 
                }
                <AgGridComponent gridConfig={this.getGridConfig('PMC Tariff Management')} />
              <Row style={{marginBottom:'20px'}}>
                <Col>
                  <div className='duplicateTariff'>
                    <CustomButton type={CustomButtonType.Submit} onClick={()=> this.onDuplicateBtnClicked()}>
                        Duplicate
                    </CustomButton>
                  </div>
                  </Col>
                  <Col className='updateTariff'>
                    <div className='updateTariff' >
                    <label className='update_standard_label_style updateLabel'>Update</label>
                    <ReactSelect
                      className='expanded_select'
                      values={pmcGridViewDataStore.selectedColValue}
                      placeholder="Select"
                      options={pmcGridViewDataStore.columnDropdownOptions}
                      onChange={(e)=>pmcGridViewDataStore.selectedColValue = e}
                      isMulti={false}
                      isSearchable={true}
                      menuPlacement='auto'
                    />
                    <label className='small_standard_label_style updateLabel'>by</label>
                    <input
                      type='number'
                      placeholder='Amount'
                      className='search_text_field input'
                      value={pmcGridViewDataStore.amountToUpdate}
                      onChange={(e) => {
                          pmcGridViewDataStore.amountToUpdate = e.target.value;
                      }}
                    />
                    <ReactSelect
                      className='select_small'
                      values={pmcGridViewDataStore.selectedUnitValue}
                      placeholder="Select"
                      options={pmcGridViewDataStore.unitValueToUpdate}
                      onChange={(e)=>pmcGridViewDataStore.selectedUnitValue = e}
                      isMulti={false}
                      isSearchable={true}
                    />
                  </div>
                  </Col>
              </Row>
             

              <Row style={{marginBottom:'10px'}}>
              <Accordion>
            <Accordion.Item eventKey='1'>
               <Accordion.Header>Notes & Attachments</Accordion.Header>
                                 <Accordion.Body>
                               
                                        <div className='flex'>
                                            <div className="rotatedText">
                                                Notes
                                            </div>
                                            <div style={{ width: '100%' }}>
                                                <Row>
                                                    <AddNotes saveRoleAccess={() => pmcGridViewDataStore.isCellEditable()} disableAddBtn={false}  disableSaveBtn={!pmcGridViewDataStore.isTariffNoteAdded} disableDeleteBtn={false} isNoteSaved={pmcGridViewDataStore.isTariffNoteSaved} onAddNewNoteCallback={() => this.onAddNewNote()} onSaveNotesCallback={() => this.onSaveNotes()} onDeleteNoteCallback={(data) => this.onDeleteNote(data.id)} agGridService={pmcGridViewDataStore.agGridServiceNotesAttachments} notesGridData={pmcGridViewDataStore.notesGridData} cellValueChangeMap={pmcGridViewDataStore.cellValueChangeMap} updateRow={(rowData) => this.onUpdateRow(rowData)} ></AddNotes>
                                                </Row>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className='flex'>
                                            <div className="rotatedText">
                                                Attachments
                                            </div>
                                            <div style={{ width: '100%' }}>
                                                <Row>
                                                    <Attachments saveRoleAccess={pmcGridViewDataStore.isCellEditable()} disabled={false} disableDeleteBtn={false} uploadFileCallback={(e) =>{this.uploadFile(e)}} onDownloadFileCallback={(data) => this.downloadAttachment(data.id)} onUpdateAttachmentCallback={() => this.updateAttachment()} onDeleteFileCallback={(data) => this.deleteAttachment(data)} agGridService={pmcGridViewDataStore.agGridServiceNotesAttachments} fileUploadGridData={pmcGridViewDataStore.fileUploadGridData}  cellValueChangeMap={pmcGridViewDataStore.cellValueChangeMapAttachment} updateRow={(rowData) => pmcGridViewDataStore.updateRowAttachment(rowData)}></Attachments>
                                                </Row>
                                            </div>
                                        </div>
                                     
                                    </Accordion.Body>
                                </Accordion.Item>
                                </Accordion>
            </Row>  

            <Row style={{marginBottom:'10px'}}>
              <div className='updateTariffButton'>
                <CustomButton type={CustomButtonType.Submit} onClick={()=> this.onUpdateClick()}>
                    Update
                </CustomButton>
              </div>
              </Row>            
              
                </div>
              </Col>
            </Row>
           
            <Modal className='modal-xl pmcGridViewBox' show={pmcGridViewDataStore.isShowModal} onHide={this.handleClose}>
              <Modal.Header className='screenHeader' closeButton>
                <Modal.Title className='screen_title'>Bulk Tariff Duplicate</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              <Row>
                  <Col>
                    <label className="standard_label_style">Start Date*</label>
                    <DatePicker
                        className='datepicker__holder'
                        selected={pmcGridViewDataStore.selectedEffectiveStartDate}
                        onChange={(e) => {
                            this.onSelectEffectiveStartDate(e);
                        }}
                        dateFormat="MM/yyyy"
                        showMonthYearPicker
                    />
                </Col>
                  <Col></Col>
                <Col className='updateTariff'>
                  <label className='update_standard_label_style updateLabel'>Update</label>
                        <ReactSelect
                          className='expanded_select'
                          values={pmcGridViewDataStore.selectedColValueForDuplicate}
                          placeholder="Select"
                          options={pmcGridViewDataStore.columnDropdownOptions}
                          onChange={(e)=>pmcGridViewDataStore.selectedColValueForDuplicate = e}
                          isMulti={false}
                          isSearchable={true}
                          menuPlacement='bottom'
                        />
                        <label className='small_standard_label_style updateLabel'>by</label>
                        <input
                          type='number'
                          placeholder='Amount'
                          className='search_text_field input'
                          value={pmcGridViewDataStore.amountToUpdateForDuplicate}
                          onChange={(e) => {
                              pmcGridViewDataStore.amountToUpdateForDuplicate = e.target.value;
                          }}
                        />
                        <ReactSelect
                          className='select_small'
                          values={pmcGridViewDataStore.selectedUnitValueForDuplicate}
                          placeholder="Select"
                          options={pmcGridViewDataStore.unitValueToUpdate}
                          onChange={(e)=>pmcGridViewDataStore.selectedUnitValueForDuplicate = e}
                          isMulti={false}
                          isSearchable={true}
                    />
                    </Col>
                    <Col></Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <CustomButton type={CustomButtonType.Submit} onClick={this.handleClose}>
                      Close
                  </CustomButton>
                  <CustomButton type={CustomButtonType.Submit} onClick={this.onBulkDuplicateClick}>
                    Duplicate
                  </CustomButton>
                </Modal.Footer>
            </Modal>
            <Modal className='modal-lg' show={pmcGridViewDataStore.isShowBulkDuplicateConfirmationModal} onHide={this.handleCloseBulkDuplicateConfirmationModal}>
              <Modal.Header className='screenHeader' closeButton>
                <Modal.Title className='screen_title'>Bulk Tariff Duplicate Confirmation</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div>Are you sure you want to duplicate the selected tariff/s?</div>
              </Modal.Body>
              <Modal.Footer>
                <CustomButton type={CustomButtonType.Submit} onClick={this.handleCloseBulkDuplicateConfirmationModal}>
                  No
                </CustomButton>
                <CustomButton disabled={pmcGridViewDataStore.isButtonDisabled?true:false} type={CustomButtonType.Submit} onClick={this.onBulkDuplicateConfirmationClicked}>
                  Yes
                </CustomButton>
              </Modal.Footer>
            </Modal>
          </div>
      );
    }

    private getGridConfig(gridId): GridOptions {
      const {
          pmcGridViewDataStore: { pmcGridViewData, agGridService }
      } = this.props;
      const { pmcGridViewDataStore } = this.props;
      const columnDefs = pmcGridViewDataStore.getColDef();
      const rowData = pmcGridViewData;
      const pmcGridViewGridParams: any = {
        rowData,
        columnDefs
      };
      return agGridService.getGridConfig(pmcGridViewGridParams, gridId);
    }
}
