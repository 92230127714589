export enum MasterDataComponentModulesName {
    AgencyMaintenance = 'Agency Maintenance',
    MiscellaneousSettings = 'Setting Maintenance',
    PMCTariff = 'PMC Tariff Management',
    BaseTariff = 'Base Tariff',
    TariffLog = 'Tariff Log',
    PipelineSystemMaintenance = 'Pipeline System Maintenance',
    VMACSSyncTest = 'VMACS Sync - Push to Test',
    VMACSSyncProd = 'VMACS Sync - Push to Prod',
    ActivateAndDeactivate ='Activate/Deactivate', 
    ActivateAndDeactivateTariff = 'Activate/Deactivate -Tariff',
    LocationMaintenance = 'Location Maintenance',
    AddSettingType = 'Add Setting Type',
    ConnectionPointMaintenance= 'Connection Point Maintenance',
    LineSegmentMaintenance = 'Line Segment Maintenance',
    GradeMaintenance = 'Grade Maintenance',
    FilingEntityMaintenance = 'Filing Entity Maintenance',
    FilingCarrierMaintenance = 'Filing Carrier Maintenance',
    ProfitCenterMaintenance= 'Profit Center Maintenance',
    TariffLogVMACSSetup= 'Tariff Log VMACS Setup',
    TariffDashboardViewButton = 'Tariff Dashboard View Button',
    TariffDashboardProcessButton = 'Tariff Dashboard Process Button',
    TariffDashboardApproveButton = 'Tariff Dashboard Approve Button',
    TestDetermination = 'Test Determination',
    ProjectDashboard = 'Project Dashboard',
    ProjectRequest = 'Project Request',
    CompleteTariffReview ='Complete Tariff Review',
    CompleteTariffApproval = 'Complete Tariff Approval',
    CompletePLAccountingApproval = 'Complete PL Accounting Approval',
    CompleteCommercialApproval = 'Complete Commercial Approval',
    CompleteSchedulingApproval = 'Complete Scheduling Approval',
    ProjectRequestCompare = 'Compare',
    CustomerMaintenance = 'Customer Maintenance',
    TariffLogMaintenance = 'Tariff Log Maintenance',
    ShipperLGMaintenance = 'Shipper Loss/Gain Configuration',
    CustomerMaintenanceAdd = 'CustomerMaintenanceAdd'
}

export const MasterDataTypeOptions = [
    'AgencyMaintenance',
    'MiscellaneousSettings',
    'PMCTariff',
    'BaseTariff',
    'TariffLog',
    'PipelineMaintenance',
    'LocationMaintenance'
];

export enum WorkFlowTypesJurisdiction {
    CANCrudeAssest = 'CAN-Crude Assest',
    CANEasternNGL = 'CAN-Eastern NGL',
    CANWesternNGL = 'CAN-Western NGL'
}

export enum MasterUserRoles {
    PMCEasternNGLApproval = 'PMCEasternNGLApproval',
    PMCWesternNGLApproval = 'PMCWesternNGLApproval',
    PMCCrudeAssetsApproval = 'PMCCrudeAssetsApproval'

}