import React, { Component } from 'react';
import { Container, Row, Col, Button, Modal } from 'react-bootstrap';
import { observer, inject } from 'mobx-react';
import '../styles/pmc-tariff.scss';
import Accordion from 'react-bootstrap/Accordion';
import { CustomButton, CustomButtonType } from 'shared/components/custom-button/custom-button';
import { PMCNotesResponse } from '../model/model';
import { ReactDatePicker } from 'common/components/datepicker/react-datepicker';
import DatePicker from 'react-datepicker';
import { pmcTariffDataStore } from '../stores';
import { PMCTariffDataStore } from '../stores/pmc-tariff-data-store';
import { ConfirmService } from 'shared/components/confirm/confirm-service';
import {
    tariffAttachmentsGridColDef,
} from './pmc-tariff-col-defs';
import history from '../../../../shared/components/header-component/component/history';
import { PMCTariffMessages, PMCTariffButtons } from '../constants/enums';
import { dataStore } from 'common/stores';
import Multiselect from 'multiselect-react-dropdown';
import { toNumber } from 'lodash';
import { localStorageService } from 'shared/services/local-storage-service';
import { accountStore } from 'features/account/stores';
import moment from 'moment';
import { Attachments } from 'common/components/attachments/attachments';
import { AddNotes } from 'common/components/add-notes/add-notes';
import { ReactSelect } from '../../../../common/components/select/select';
import { projectRequestStep1DataStore } from 'features/tariff-management/project-request/stores';

/* eslint-disable */
interface PMCTariffContainerProps {
    pmcTariffDataStore: PMCTariffDataStore;
}

interface PMCTariffContainerState {
    lastTariffNoteID: number;
}

@inject('pmcTariffDataStore')
@observer
export class PMCTariffContainer extends Component<
PMCTariffContainerProps,
PMCTariffContainerState
> {
    constructor(props) {
        super(props);
        this.state = {
            lastTariffNoteID: 0
        };
        dataStore.setModuleName('PMC Tariff Management');
    }

    componentDidMount() {
        const { pmcTariffDataStore } = this.props;
        const previousLocation = history?.location?.state as { from?: string }
        pmcTariffDataStore.previousLoc = previousLocation?.from;
        history.push('/PMCTariff');
        if(previousLocation?.from && previousLocation.from==='/PMCDashboard'){
            pmcTariffDataStore.getTariffRequestTypeDropdownData();
        } 
        else {
            pmcTariffDataStore.init();   
        }
    }

    // componentDidUpdate() {
    //     const filters = localStorageService.get('PMC Tariff Management_filter');
    //     pmcTariffDataStore.agGridService.setAllFilters(filters);
    //     const columnVisible = localStorageService.get('PMC Tariff Management_' + accountStore.userName);
    //     pmcTariffDataStore.agGridService.setColumnVisibility(columnVisible);
    //     dataStore.showUnsavedWarningOnTabCloseOrReload();
    // }

    async componentWillUnmount() {
        if (pmcTariffDataStore.isPMCTariffDuplicated) {
            await pmcTariffDataStore.deletePMCTariff();
        }
        pmcTariffDataStore.resetPMCTariff();
    }

    onSelectTariffRequestType = (e) => {
        pmcTariffDataStore.setTariffRequestType(e);
    };

    onSelectSystemGroup = (e) => {
        pmcTariffDataStore.isFromTariffScreen = true;
        pmcTariffDataStore.setSystemGroup(e);
    };

    onSelectEffectiveStartDate = (e) => {
        pmcTariffDataStore.setEffectiveStartDate(e);
    };

    onSelectEffectiveEndDate = (e) => {
        pmcTariffDataStore.setEffectiveEndDate(e);
    };

    onSelectPipelineGroup = (e) => {
        pmcTariffDataStore.setPipelineGroup(e);
    };

    onSelectEffectiveDate = (e) => {
        pmcTariffDataStore.setEffectiveDate(e);
    };

    onSelectEscalationMonth = (e) => {
        pmcTariffDataStore.setEscalationMonth(e);
    };

    onChangeEscalationAmount = (e) => {
        pmcTariffDataStore.setEscalationAmount(e.target.value);
    };

    onChangeOperator = (e) => {
        pmcTariffDataStore.setOperator(e);
    };

    onSelectProducts = (selectedList, selectedItem) => {
        pmcTariffDataStore.setProducts(selectedList);
    };

    onRemoveProducts(selectedList, removedItem) {
        pmcTariffDataStore.setProducts(selectedList);
    }

    onSelectExternalSystems = (selectedList, selectedItem) => {
        pmcTariffDataStore.setExternalSystems(selectedList);
    };

    onRemoveExternalSystems(selectedList, removedItem) {
        pmcTariffDataStore.setExternalSystems(selectedList);
    }

    onChangeDescription = (e) => {
        pmcTariffDataStore.setDescription(e.target.value);
    };

    onSelectDistributionType = (e) => {
        pmcTariffDataStore.setDistributionType(e);
    };

    onSelectProductType = (e) => {
        pmcTariffDataStore.setProductType(e);
    };

    onSelectTariffApplication = (e) => {
        pmcTariffDataStore.setTariffApplication(e);
    };

    onChangeAgreement = (e) => {
        pmcTariffDataStore.setAgreement(e.target.value);
    };

    onChangeAgreementEndDate = (e) => {
        pmcTariffDataStore.setAgreementEndDate(e);
    };

    onSelectUnit = (e) => {
        pmcTariffDataStore.setUnit(e);
    };

    onSelectCurrency = (e) => {
        pmcTariffDataStore.setCurrency(e);
    };

    onSelectRegulator = (e) => {
        pmcTariffDataStore.setRegulator(e);
    };

    onSelectFacilityCode = (e) => {
        pmcTariffDataStore.setFacilityCode(e);
    };

    onSelectLSD = (e) => {
        pmcTariffDataStore.setLSD(e);
    };

    onSelectDestination = (e) => {
        pmcTariffDataStore.setDestination(e);
    };

    onSelectShrinkage = (e) => {
        pmcTariffDataStore.setShrinkage(e);
    };

    onSelectLossAllowance = (e) => {
        pmcTariffDataStore.setLossAllowance(e);
    };

    savePMCTariff = () => {
        pmcTariffDataStore.savePMCTariff();
    };

    savePMCTariffDetailFeeDetail = () => {
        pmcTariffDataStore.savePMCTariffDetailFeeDetail();
    };

    deletePMCTariff = () => {
        pmcTariffDataStore.deletePMCTariff();
    };

    duplicatePMCTariff = () => {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(() => {
            pmcTariffDataStore.duplicatePMCTariff();
        },
            'Duplicate Tariff Confirmation',
            'Are you sure you want to duplicate the tariff?'
        );
    }

    cancelPMCTariff = () => {
        if(pmcTariffDataStore.previousLoc==='/PMCGridView'){
            history.push('/PMCGridView')
        } else if(pmcTariffDataStore.previousLoc==='/project-request-step-1'){
            history.push('/project-request-step-1', {workflowId:projectRequestStep1DataStore.workflowId})
        } else {
            history.push('/PMCDashboard')
        }
        pmcTariffDataStore.resetPMCTariff();
    };

    onCancelEditTariff = () => {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
            async () => {
                if (pmcTariffDataStore.isPMCTariffDuplicated) {
                    await pmcTariffDataStore.deletePMCTariff();
                }
                pmcTariffDataStore.isTariffEditingCancelled = true;
                pmcTariffDataStore.resetTariffEditingData();
                pmcTariffDataStore.isTariffSaved = true;
                pmcTariffDataStore.isTariffEditingEnabled = false;
            },
            '',
            PMCTariffMessages.CANCEL,
        );
    };

    onEditTariff = () => {
        pmcTariffDataStore.isTariffSaved = false;
        pmcTariffDataStore.isTariffEditingEnabled = true;
    }

    onSaveNotes = () => {
        pmcTariffDataStore.saveTariffNotes();
    };

    onDeleteNote = (noteID) => {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(() => {
            pmcTariffDataStore.deleteTariffNote(noteID);
        },
            'Delete',
            'Are you sure you want to delete the selected note?'
        );
        pmcTariffDataStore.NotesGridData.length > 0 ? pmcTariffDataStore.isTariffNoteAdded = true : false;
    };

    handleCloseEndDateConfirmationModal(){
      pmcTariffDataStore.handleCloseEndDateConfirmationModal();
    }

    setTariffEndDate(){
      pmcTariffDataStore.setTariffEndDate();
    }

    onChangePrimaryRate = (e) => {
        const val = e.target.value;
        pmcTariffDataStore.setPrimaryRate(toNumber(parseFloat(val)));
    };

    onEditPrimaryRate = (e) => {
        const val = e.target.value;
        //pmcTariffDataStore.setPrimaryRate(parseFloat(val).toFixed(2));
        pmcTariffDataStore.setPrimaryRate(Math.round( ( parseFloat(val) + Number.EPSILON ) * 100 ) / 100);
    };

    onChangeSecondaryRate = (e) => {
        const val = e.target.value;
        pmcTariffDataStore.setSecondaryRate(toNumber(parseFloat(val)));
    };

    onEditSecondaryRate = (e) => {
        const val = e.target.value;
      //  pmcTariffDataStore.setSecondaryRate(parseFloat(val).toFixed(2));
      pmcTariffDataStore.setSecondaryRate(Math.round( ( parseFloat(val) + Number.EPSILON ) * 100 ) / 100);
    };

    onChangeLossAllowance = (e) => {
        pmcTariffDataStore.setLossAllowance(e.target.value);
    };

    editLossAllowance = (e) => {
        pmcTariffDataStore.setIsLossAllowanceEditable(e.target.checked);
    };

    onChangeQualityMgmtFee = (e) => {
        const val = e.target.value;
        pmcTariffDataStore.setQualityMgmtFee(toNumber(parseFloat(val)));
    };

    onEditQualityMgmtFee = (e) => {
        const val = e.target.value;
        // pmcTariffDataStore.setQualityMgmtFee(parseFloat(val).toFixed(2));
        pmcTariffDataStore.setQualityMgmtFee(Math.round( ( parseFloat(val) + Number.EPSILON ) * 100 ) / 100);
    };

    editQualityMgmtFee = (e) => {
        pmcTariffDataStore.setIsQualityMgmtFeeEditable(e.target.checked);
    };

    onChangeElectronicReportingFee = (e) => {
        const val = e.target.value;
        pmcTariffDataStore.setElectronicReportingFee(toNumber(parseFloat(val)));
    };

    onEditElectronicReportingFee = (e) => {
        const val = e.target.value;
        // pmcTariffDataStore.setElectronicReportingFee(parseFloat(val).toFixed(2));
        pmcTariffDataStore.setElectronicReportingFee(Math.round( ( parseFloat(val) + Number.EPSILON ) * 100 ) / 100);
    };

    editElectronicReportingFee = (e) => {
        pmcTariffDataStore.setIsElectronicReportingFeeEditable(e.target.checked);
    };

    onChangeShrinkage = (e) => {
        pmcTariffDataStore.setShrinkage(e.target.value);
    };

    editShrinkage = (e) => {
        pmcTariffDataStore.setIsShrinkageEditable(e.target.checked);
    };

    onChangeBSW = (e) => {
        const val = e.target.value;
        pmcTariffDataStore.setBSW(toNumber(parseFloat(val)));
    };

    onEditBSW = (e) => {
        const val = e.target.value;
        // pmcTariffDataStore.setBSW(parseFloat(val).toFixed(2));
        pmcTariffDataStore.setBSW(Math.round((parseFloat(val) + Number.EPSILON) * 100) / 100);
    };

    editBSW = (e) => {
        pmcTariffDataStore.setIsBSWEditable(e.target.checked);
    };

    onChangedeliveryTerminallingFee = (e) => {
        const val = e.target.value;
        pmcTariffDataStore.setDeliveryTerminallingFee(toNumber(parseFloat(val)));
    };

    onEditdeliveryTerminallingFee = (e) => {
        const val = e.target.value;
        // pmcTariffDataStore.setDeliveryTerminallingFee(parseFloat(val).toFixed(2));
        pmcTariffDataStore.setDeliveryTerminallingFee(Math.round((parseFloat(val) + Number.EPSILON) * 100) / 100);
    };

    onChangeIntraSystemTransferFee = (e) => {
        const val = e.target.value;
        pmcTariffDataStore.setIntraSystemTransferFee(toNumber(parseFloat(val)));
    };

    onEditIntraSystemTransferFee = (e) => {
        const val = e.target.value;
        // pmcTariffDataStore.setIntraSystemTransferFee(parseFloat(val).toFixed(2));
        pmcTariffDataStore.setIntraSystemTransferFee(Math.round((parseFloat(val) + Number.EPSILON) * 100) / 100);
    };

    onChangeReceiptTerminaling = (e) => {
        const val = e.target.value;
        pmcTariffDataStore.setReceiptTerminalling(toNumber(parseFloat(val)));
    };

    onEditReceiptTerminaling = (e) => {
        const val = e.target.value;
      // pmcTariffDataStore.setReceiptTerminalling(parseFloat(val).toFixed(2)); 
      pmcTariffDataStore.setReceiptTerminalling(Math.round((parseFloat(val)+ Number.EPSILON) * 100) / 100);
    };

    onChangeInjection = (e) => {
        const val = e.target.value;
        pmcTariffDataStore.setInjectionFee(toNumber(parseFloat(val)));
    };

    onEditInjection = (e) => {
        const val = e.target.value;
        // pmcTariffDataStore.setInjectionFee(parseFloat(val).toFixed(2));
        pmcTariffDataStore.setInjectionFee(Math.round((parseFloat(val)+ Number.EPSILON) * 100) / 100);
    };

    onChangeLmci = (e) => {
        const val = e.target.value;
        pmcTariffDataStore.setLmciAbandonment(toNumber(parseFloat(val)));
    };

    onEditLmci = (e) => {
        const val = e.target.value;
        // pmcTariffDataStore.setLmciAbandonment(parseFloat(val).toFixed(2));
        pmcTariffDataStore.setLmciAbandonment(Math.round((parseFloat(val)+ Number.EPSILON) * 100) / 100);
    };

    onChangeSurcharge = (e) => {
        const val = e.target.value;
        pmcTariffDataStore.setSurcharge(toNumber(parseFloat(val)));
    };

    onEditSurcharge = (e) => {
        const val = e.target.value;
        // pmcTariffDataStore.setSurcharge(parseFloat(val).toFixed(2));
        pmcTariffDataStore.setSurcharge(Math.round((parseFloat(val) + Number.EPSILON) * 100) / 100);
    };

    editIntraSystemTransferFee = (e) => {
        pmcTariffDataStore.setIsIntraSystemTransferFeeEditable(e.target.checked);
    };

    onChangeLumpSumFees = (e) => {
        const val = e.target.value;
        pmcTariffDataStore.setLumpSumFees(toNumber(parseFloat(val)));
    };

    onEditLumpSumFees = (e) => {
        const val = e.target.value;
        // pmcTariffDataStore.setLumpSumFees(parseFloat(val).toFixed(2));
        pmcTariffDataStore.setLumpSumFees(Math.round((parseFloat(val) + Number.EPSILON) * 100) / 100);
    };

    onAddNewNote() {
        const pmcTariffNoteID = this.state.lastTariffNoteID - 1;
        const rowData = [{
            pmcTariffNoteID: pmcTariffNoteID,
            pmcTariffDetailID: pmcTariffDataStore.pmcTariffDetailID,
            notes: '',
            createUser: pmcTariffDataStore.createdUser,
            createDate: moment.utc(new Date()).format('YYYY-MM-DDTHH:mm:ss'),
            updateUser: null,
            updateDate: null,
            pmcTariffDetail: null,
        } as unknown as PMCNotesResponse, ...pmcTariffDataStore.notesGridData];
        pmcTariffDataStore.setPMCTariffNoteGridData(rowData);
        this.setState({ ...this.state, lastTariffNoteID: pmcTariffNoteID });
        pmcTariffDataStore.NotesGridData.length > 0 ? pmcTariffDataStore.isTariffNoteAdded = true : false;
    };

    getAttachmentsColDef() {
        return tariffAttachmentsGridColDef;
    }

    getAttachmentsGridConfig() {
        const {
            pmcTariffDataStore: { fileUploadGridData, agGridServiceForAddAttachment },
        } = this.props;
        const tariffAttachmentsGridParams: any = {
            rowData: fileUploadGridData,
            columnDefs: this.getAttachmentsColDef(),
        };
        return agGridServiceForAddAttachment.getGridConfig(tariffAttachmentsGridParams);
    }

    uploadFile(e) {
        pmcTariffDataStore.uploadFile(e);
    };

    downloadAttachment(fileID) {
        pmcTariffDataStore.downloadAttachment(fileID);
    };

    deleteAttachment = async (files) => {
        const requestBody: any = await pmcTariffDataStore.agGridService.getSelectedRows();
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
            () => {
                pmcTariffDataStore.deleteAttachment(files);
            },
            'Delete',
            'Are you sure you want to delete the selected attachment?'
        );
    };

    updateAttachment = () => {
        pmcTariffDataStore.updateAttachment();
    }

    onHandleSubmitTariff() {
        const confirmService = new ConfirmService();
        confirmService.showConfirmDialog(
            () => console.log('Submitted for Approval..'),
            '',
            PMCTariffMessages.SUBMIT_FOR_APPROVAL,
        );
    }

    render(): React.ReactNode {
        if (pmcTariffDataStore.selectedTariffRequestType) {
            return (
                <div className='pmcTariffBox'>
                    <Container fluid>
                        <div>
                            <div className='screenHeader'>
                                <h2 className='screen_title'>{pmcTariffDataStore.previousLoc==='/PMCGridView' || pmcTariffDataStore.previousLoc==='/PMCDashboard' || pmcTariffDataStore.previousLoc==='/project-request-step-1' ? 'Edit Tariff' : 'Create New Tariff'}</h2>
                            </div>
                            <div className='selectContainer'>
                                <Row className='tariffNumber'>
                                    <Col>
                                        <label className='standard_label_style'>Asset Group</label>
                                        <input
                                            type='text'
                                            className='search_text_field'
                                            value={pmcTariffDataStore.selectedTariffRequestType.label}
                                            readOnly={true}
                                        />
                                    </Col>
                                    <Col>
                                        <label className='standard_label_style'>Tariff Number</label>
                                        <input
                                            type='text'
                                            className='search_text_field'
                                            value={pmcTariffDataStore.pmcTariffNumber}
                                            readOnly={true}
                                        />
                                    </Col>
                                    <Col>
                                        <label className='standard_label_style'>Status</label>
                                        <input
                                            type='text'
                                            className='search_text_field'
                                            value={pmcTariffDataStore.tariffStatus}
                                            readOnly={true}
                                        />
                                    </Col>
                                </Row>
                            </div>

                            <Accordion defaultActiveKey='0'>
                                <Accordion.Item eventKey='0'>
                                    <Accordion.Header>Tariff</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='flex'>
                                            <div className='newTariff'>
                                                <Row>
                                                    <Col>
                                                        <label className='standard_label_style'>Pipeline System*</label>
                                                        <ReactSelect
                                                            className='expanded_select'
                                                            values={pmcTariffDataStore.selectedSystemGroup}
                                                            placeholder="Select"
                                                            options={pmcTariffDataStore.systemGroupDropdownOptions}
                                                            onChange={this.onSelectSystemGroup}
                                                            isMulti={false}
                                                            isSearchable={true}
                                                            disabled={pmcTariffDataStore.isTariffSaved}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <label className="standard_label_style">Start Date*</label>
                                                        <DatePicker
                                                            className='datepicker__holder'
                                                            selected={pmcTariffDataStore.selectedEffectiveStartDate}
                                                            onChange={(e) => {
                                                                this.onSelectEffectiveStartDate(e);
                                                            }}
                                                            dateFormat="MM/yyyy"
                                                            showMonthYearPicker
                                                            disabled={pmcTariffDataStore.isTariffSaved}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <label className="standard_label_style">End Date</label>
                                                        <DatePicker
                                                            className='datepicker__holder'
                                                            selected={pmcTariffDataStore.selectedEffectiveEndDate}
                                                            onChange={(e) => {
                                                                this.onSelectEffectiveEndDate(e);
                                                            }}
                                                            dateFormat="MM/yyyy"
                                                            showMonthYearPicker
                                                            disabled={pmcTariffDataStore.isTariffSaved}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <label className='standard_label_style'>Pipeline Region*</label>
                                                        <ReactSelect
                                                            className='expanded_select'
                                                            values={pmcTariffDataStore.selectedPipelineGroup}
                                                            placeholder="Select"
                                                            options={pmcTariffDataStore.pipelineGroupDropdownOptions}
                                                            onChange={this.onSelectPipelineGroup}
                                                            isMulti={false}
                                                            isSearchable={true}
                                                            disabled={pmcTariffDataStore.isTariffSaved}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <label className='standard_label_style'>Destination*</label>
                                                        <ReactSelect
                                                            className='select'
                                                            values={pmcTariffDataStore.selectedDestination}
                                                            placeholder="Select"
                                                            options={pmcTariffDataStore.destinationDropdownOptions}
                                                            onChange={this.onSelectDestination}
                                                            isMulti={false}
                                                            isSearchable={true}
                                                            disabled={pmcTariffDataStore.isTariffSaved}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <label className='standard_label_style'>Unit*</label>
                                                        <ReactSelect
                                                            className='select lastColInput'
                                                            values={pmcTariffDataStore.selectedUnit}
                                                            placeholder="Select"
                                                            options={pmcTariffDataStore.unitDropdownOptions}
                                                            onChange={this.onSelectUnit}
                                                            isMulti={false}
                                                            isSearchable={true}
                                                            disabled={pmcTariffDataStore.isTariffSaved}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col>
                                                        <label className='standard_label_style'>Operator*</label>
                                                        <ReactSelect
                                                            className='expanded_select'
                                                            values={pmcTariffDataStore.enteredOperator}
                                                            placeholder="Select"
                                                            options={pmcTariffDataStore.operatorDropdownOptions}
                                                            onChange={this.onChangeOperator}
                                                            isMulti={false}
                                                            isSearchable={true}
                                                            disabled={pmcTariffDataStore.isTariffSaved}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <label className='standard_label_style'>Tariff Application*</label>
                                                        <ReactSelect
                                                            className='select'
                                                            values={pmcTariffDataStore.selectedTariffApplication}
                                                            placeholder="Select"
                                                            options={pmcTariffDataStore.tariffApplicationDropdownOptions}
                                                            onChange={this.onSelectTariffApplication}
                                                            isMulti={false}
                                                            isSearchable={true}
                                                            disabled={pmcTariffDataStore.isTariffSaved}
                                                        />
                                                    </Col>
                                                    <Col className='currency'>
                                                        <label className='standard_label_style'>Currency*</label>
                                                        <ReactSelect
                                                            className='select lastColInput'
                                                            values={pmcTariffDataStore.selectedCurrency}
                                                            placeholder="Select"
                                                            options={pmcTariffDataStore.currencyDropdownOptions}
                                                            onChange={this.onSelectCurrency}
                                                            isMulti={false}
                                                            isSearchable={true}
                                                            disabled={pmcTariffDataStore.isTariffSaved}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                <Col>
                                                            <label className='standard_label_style'>Description</label>
                                                            <input
                                                               maxLength={256}
                                                                type='text'
                                                                className='input_box'
                                                                value={pmcTariffDataStore.description}
                                                                onChange={(e) => {
                                                                    this.onChangeDescription(e);
                                                                }}
                                                                disabled={pmcTariffDataStore.isTariffSaved}
                                                            />
                                                        </Col>
                                                    <Col>
                                                        <label className='standard_label_style'>Distribution*</label>
                                                        <ReactSelect
                                                            className='select'
                                                            values={pmcTariffDataStore.selectedDistributionType}
                                                            placeholder="Select"
                                                            options={pmcTariffDataStore.distributionTypeDropdownOptions}
                                                            onChange={this.onSelectDistributionType}
                                                            isMulti={false}
                                                            isSearchable={true}
                                                            disabled={pmcTariffDataStore.isTariffSaved}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <label className='standard_label_style'>Product Type*</label>
                                                        <ReactSelect
                                                            className='select lastColInput'
                                                            values={pmcTariffDataStore.selectedProductType}
                                                            placeholder="Select"
                                                            options={pmcTariffDataStore.productTypeDropdownOptions}
                                                            onChange={this.onSelectProductType}
                                                            isMulti={false}
                                                            isSearchable={true}
                                                            disabled={pmcTariffDataStore.isTariffSaved}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                <Col>
                                                        <label className='standard_label_style'>Facility ID</label>
                                                        <ReactSelect
                                                            className='expanded_select'
                                                            values={pmcTariffDataStore.selectedFacilityCode}
                                                            placeholder="Select"
                                                            options={pmcTariffDataStore.facilityCodeDropdownOptions}
                                                            onChange={this.onSelectFacilityCode}
                                                            isMulti={false}
                                                            isSearchable={true}
                                                            disabled={pmcTariffDataStore.isTariffSaved}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <label className='standard_label_style'>External System</label>
                                                        <Multiselect
                                                            className='multiselect'
                                                            isObject={false}
                                                            selectedValues={pmcTariffDataStore.selectedExternalSystems}
                                                            onKeyPressFn={function noRefCheck() { }}
                                                            onRemove={this.onRemoveExternalSystems}
                                                            onSearch={function noRefCheck() { }}
                                                            onSelect={this.onSelectExternalSystems}
                                                            options={pmcTariffDataStore.externalSystemDropdownOptions.map(
                                                                (option) => option.valueText,
                                                            )}
                                                            disable={pmcTariffDataStore.isTariffSaved}
                                                        />
                                                    </Col>
                                                    <Col>
                                                        <label className='standard_label_style'>Product*</label>
                                                        <Multiselect
                                                            className='multiselectProduct'
                                                            isObject={false}
                                                            selectedValues={pmcTariffDataStore.selectedProducts}
                                                            onKeyPressFn={function noRefCheck() { }}
                                                            onRemove={this.onRemoveProducts}
                                                            onSearch={function noRefCheck() { }}
                                                            onSelect={this.onSelectProducts}
                                                            options={pmcTariffDataStore.productDropdownOptions.map(
                                                                (option) => option.valueText,
                                                            )}
                                                            disable={pmcTariffDataStore.isTariffSaved}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                <Col>
                                                        <label className='standard_label_style'>LSD</label>
                                                        <ReactSelect
                                                            className='expanded_select'
                                                            values={pmcTariffDataStore.selectedLSD}
                                                            placeholder="Select"
                                                            options={pmcTariffDataStore.lsdDropdownOptions}
                                                            onChange={this.onSelectLSD}
                                                            isMulti={false}
                                                            isSearchable={true}
                                                            disabled={pmcTariffDataStore.isTariffSaved}
                                                        />
                                                    </Col>
                                                </Row>
                                                <Row>
                                                <Col className='regulator '>
                                                        <label className='standard_label_style'>Regulator</label>
                                                        <ReactSelect
                                                            className='expanded_select'
                                                            values={pmcTariffDataStore.selectedRegulator}
                                                            placeholder="Select"
                                                            options={pmcTariffDataStore.regulatorDropdownOptions}
                                                            onChange={this.onSelectRegulator}
                                                            isMulti={false}
                                                            isSearchable={true}
                                                            disabled={pmcTariffDataStore.isTariffSaved}
                                                        />
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className='fees'>
                                            <div className='flex'>
                                                <div className='rotatedText'>Agreement & Fees</div>
                                                <div className='systemFees'>
                                                    <Row>
                                                        <Col>
                                                            <label className='standard_label_style'>Effective Date*</label>
                                                            <ReactDatePicker
                                                                selectedDate={pmcTariffDataStore.selectedEffectiveDate}
                                                                onDateChange={(e) => {
                                                                    this.onSelectEffectiveDate(e);
                                                                }}
                                                                disabled={pmcTariffDataStore.isTariffSaved}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <label className='standard_label_style'>Primary Pipeline Tariff</label>
                                                            <input
                                                                type='number'
                                                                step="0.01"
                                                                min="0.00"
                                                                className='search_text_field input'
                                                                value={pmcTariffDataStore.enteredPrimaryRate}
                                                                disabled={pmcTariffDataStore.isTariffSaved}
                                                                onChange={(e) => {
                                                                    this.onChangePrimaryRate(e);
                                                                }}
                                                                onBlur={(e) => {
                                                                    this.onEditPrimaryRate(e);
                                                                }}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <label className='standard_label_style'>
                                                                Delivery Terminaling
                                                            </label>
                                                            <input
                                                                type='number'
                                                                step="0.01"
                                                                min="0.00"
                                                                className='search_text_field input'
                                                                value={pmcTariffDataStore.deliveryTerminallingFee}
                                                                onChange={(e) => {
                                                                    this.onChangedeliveryTerminallingFee(e);
                                                                }}
                                                                onBlur={(e) => {
                                                                    this.onEditdeliveryTerminallingFee(e);
                                                                }}
                                                                disabled={pmcTariffDataStore.isTariffSaved}
                                                            />
                                                        </Col>
                                                        <Col></Col>
                                                    </Row>
                                                    <Row>
                                                        <Col className='hideCol'></Col>
                                                        <Col>
                                                            <label className='standard_label_style'>Secondary Pipeline Tariff</label>
                                                            <input
                                                                type='number'
                                                                step="0.01"
                                                                min="0.00"
                                                                className='search_text_field input'
                                                                value={pmcTariffDataStore.enteredSecondaryRate}
                                                                onChange={(e) => {
                                                                    this.onChangeSecondaryRate(e);
                                                                }}
                                                                onBlur={(e) =>{this.onEditSecondaryRate(e)}}
                                                                disabled={pmcTariffDataStore.isTariffSaved}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <label className='standard_label_style shrinkageLossAllowanceLabel'>Loss Allowance (% Stream)</label>
                                                            <ReactSelect
                                                                className='select_small'
                                                                values={pmcTariffDataStore.lossAllowance}
                                                                placeholder="Select"
                                                                options={pmcTariffDataStore.lossAllowanceDropdownOptions}
                                                                onChange={this.onSelectLossAllowance}
                                                                isMulti={false}
                                                                isSearchable={true}
                                                                disabled={!pmcTariffDataStore.isLossAllowanceEditable || pmcTariffDataStore.isTariffSaved}
                                                            />
                                                            <label style={{ "padding": '5px', 'width': '150px' }}>
                                                                <input type="checkbox" onChange={(e) => this.editLossAllowance(e)} checked={pmcTariffDataStore.isLossAllowanceEditable} disabled={pmcTariffDataStore.isTariffSaved}/>
                                                            </label>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <label className='standard_label_style'>Agreement</label>
                                                            <input
                                                                type='text'
                                                                className='search_text_field input'
                                                                value={pmcTariffDataStore.enteredAgreement}
                                                                onChange={(e) => {
                                                                    this.onChangeAgreement(e);
                                                                }}
                                                                disabled={pmcTariffDataStore.isTariffSaved}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <label className='standard_label_style'>Surcharge</label>
                                                            <input
                                                                type='number'
                                                                step="0.01"
                                                                min="0.00"
                                                                value={pmcTariffDataStore.enteredSurcharge}
                                                                className='search_text_field input'
                                                                onChange={(e) => {
                                                                    this.onChangeSurcharge(e);
                                                                }}
                                                                onBlur={(e) => {
                                                                    this.onEditSurcharge(e);
                                                                }}
                                                                disabled={pmcTariffDataStore.isTariffSaved}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <label className='standard_label_style shrinkageLossAllowanceLabel'>Shrinkage (% Stream)</label>
                                                            <ReactSelect
                                                                className='select_small'
                                                                values={pmcTariffDataStore.shrinkage}
                                                                placeholder="Select"
                                                                options={pmcTariffDataStore.shrinkageDropdownOptions}
                                                                onChange={this.onSelectShrinkage}
                                                                isMulti={false}
                                                                isSearchable={true}
                                                                disabled={!pmcTariffDataStore.isShrinkageEditable || pmcTariffDataStore.isTariffSaved}
                                                            />
                                                            <label style={{ "padding": '5px', 'width': '150px' }}>
                                                                <input type="checkbox" onChange={(e) => this.editShrinkage(e)} checked={pmcTariffDataStore.isShrinkageEditable} disabled={pmcTariffDataStore.isTariffSaved} />
                                                            </label>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <label className='standard_label_style'>Agreement End Date</label>
                                                            <ReactDatePicker
                                                                selectedDate={pmcTariffDataStore.selectedAgreementEndDate}
                                                                onDateChange={(e) => {
                                                                    this.onChangeAgreementEndDate(e);
                                                                }}
                                                                disabled={pmcTariffDataStore.isTariffSaved}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <label className='standard_label_style'>
                                                                LMCI Abandonment S/C
                                                            </label>
                                                            <input
                                                                type='number'
                                                                step="0.01"
                                                                min="0.00"
                                                                value={pmcTariffDataStore.enteredLMCIAbandonmentRate}
                                                                className='search_text_field input'
                                                                onChange={(e) => {
                                                                    this.onChangeLmci(e);
                                                                }}
                                                                onBlur={(e) => {
                                                                    this.onEditLmci(e);
                                                                }}
                                                                disabled={pmcTariffDataStore.isTariffSaved}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <label className='standard_label_style'>BS&W Penalty</label>
                                                            <input
                                                                type='number'
                                                                step="0.01"
                                                                min="0.00"
                                                                className='search_text_field input'
                                                                value={pmcTariffDataStore.bsw}
                                                                disabled={!pmcTariffDataStore.isBSWEditable || pmcTariffDataStore.isTariffSaved}
                                                                onChange={(e) => {
                                                                    this.onChangeBSW(e);
                                                                }}
                                                                onBlur={(e) => {
                                                                    this.onEditBSW(e);
                                                                }}
                                                            />
                                                            <label style={{ "padding": '5px', 'width': '150px' }}>
                                                                <input type="checkbox" onChange={(e) => this.editBSW(e)} checked={pmcTariffDataStore.isBSWEditable} disabled={pmcTariffDataStore.isTariffSaved} />
                                                            </label>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <label className='standard_label_style'>Escalation Month</label>
                                                            <ReactSelect
                                                                className='select escalationMonth'
                                                                values={pmcTariffDataStore.selectedEscalationMonth}
                                                                placeholder="Select"
                                                                options={pmcTariffDataStore.monthNames}
                                                                onChange={(e: any) => {this.onSelectEscalationMonth(e)}}
                                                                isMulti={false}
                                                                isSearchable={true}
                                                                disabled={pmcTariffDataStore.isTariffSaved}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <label className='standard_label_style'>Offload/Injection Fee</label>
                                                            <input
                                                                type='number'
                                                                step="0.01"
                                                                min="0.00"
                                                                value={pmcTariffDataStore.enteredInjectionFee}
                                                                className='search_text_field input'
                                                                onChange={(e) => {
                                                                    this.onChangeInjection(e);
                                                                }}
                                                                onBlur={(e) => {
                                                                    this.onEditInjection(e);
                                                                }}
                                                                disabled={pmcTariffDataStore.isTariffSaved}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <label className='standard_label_style'>
                                                                Intra Sys Transfer Fee
                                                            </label>
                                                            <input
                                                                type='number'
                                                                min="0.00"
                                                                className='search_text_field input'
                                                                value={pmcTariffDataStore.intraSystemTransferFee}
                                                                disabled={!pmcTariffDataStore.isIntraSystemTransferFeeEditable || pmcTariffDataStore.isTariffSaved}
                                                                onChange={(e) => {
                                                                    this.onChangeIntraSystemTransferFee(e);
                                                                }}
                                                                onBlur={(e) => {
                                                                    this.onEditIntraSystemTransferFee(e);
                                                                }}
                                                            />
                                                            <label style={{ "padding": '5px', 'width': '150px' }}>
                                                                <input type="checkbox" onChange={(e) => this.editIntraSystemTransferFee(e)} checked={pmcTariffDataStore.isIntraSystemTransferFeeEditable} disabled={pmcTariffDataStore.isTariffSaved} />
                                                            </label>
                                                        </Col>
                                                    </Row>
                                                    <Row>
                                                        <Col>
                                                            <label className='standard_label_style'>Escalation Amount</label>
                                                            <input
                                                                type='text'
                                                                className='search_text_field input'
                                                                value={pmcTariffDataStore.enteredEscalationAmount}
                                                                onChange={(e) => {
                                                                    this.onChangeEscalationAmount(e);
                                                                }}
                                                                disabled={pmcTariffDataStore.isTariffSaved}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <label className='standard_label_style'>
                                                                Receipt Terminaling
                                                            </label>
                                                            <input
                                                                type='number'
                                                                step="0.01"
                                                                min="0.00"
                                                                value={pmcTariffDataStore.enteredRecieptTerminalingRate}
                                                                className='search_text_field input'
                                                                onChange={(e) => {
                                                                    this.onChangeReceiptTerminaling(e);
                                                                }}
                                                                onBlur={(e) => {
                                                                    this.onEditReceiptTerminaling(e);
                                                                }}
                                                                disabled={pmcTariffDataStore.isTariffSaved}
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <label className='standard_label_style'>Lump Sum Fee</label>
                                                            <input
                                                                type='number'
                                                                step="0.01"
                                                                min="0.00"
                                                                className='search_text_field input'
                                                                value={pmcTariffDataStore.lumpSumFees}
                                                                onChange={(e) => {
                                                                    this.onChangeLumpSumFees(e);
                                                                }}
                                                                onBlur={(e) => {
                                                                    this.onEditLumpSumFees(e);
                                                                }}
                                                                disabled={pmcTariffDataStore.isTariffSaved}
                                                            />
                                                        </Col>
                                                        <Col></Col>
                                                    </Row>

                                                    <div className='mainDiv'>
                                                        <div style={{display:'inline-block'}}>
                                                            <Col>
                                                                <label className='standard_label_style'>Quality Mgmt Fee</label>
                                                            <input
                                                                type='number'
                                                                step="0.01"
                                                                min="0.00"
                                                                value={pmcTariffDataStore.enteredQualityMgmtFee}
                                                                className='search_text_field input'
                                                                disabled={!pmcTariffDataStore.isQualityMgmtFeeEditable || pmcTariffDataStore.isTariffSaved}
                                                                onChange={(e) => {
                                                                    this.onChangeQualityMgmtFee(e);
                                                                }}
                                                                onBlur={(e) => {
                                                                    this.onEditQualityMgmtFee(e);
                                                                }}
                                                            />
                                                            <label style={{ "padding": '5px', 'width': '150px' }}>
                                                                <input type="checkbox" onChange={(e) => this.editQualityMgmtFee(e)} checked={pmcTariffDataStore.isQualityMgmtFeeEditable} disabled={pmcTariffDataStore.isTariffSaved} />
                                                            </label>
                                                            </Col>
                                                            <Col>
                                                            <label className='standard_label_style'>
                                                                Electronic Reporting
                                                            </label>
                                                            <input
                                                                type='number'
                                                                step="0.01"
                                                                min="0.00"
                                                                className='search_text_field input'
                                                                value={pmcTariffDataStore.enteredElectronicReportingFee}
                                                                disabled={!pmcTariffDataStore.isElectronicReportingFeeEditable || pmcTariffDataStore.isTariffSaved}
                                                                onChange={(e) => {
                                                                    this.onChangeElectronicReportingFee(e);
                                                                }}
                                                                onBlur={(e) => {
                                                                    this.onEditElectronicReportingFee(e);
                                                                }}
                                                            />
                                                            <label style={{ "padding": '5px', 'width': '150px' }}>
                                                                <input type="checkbox" onChange={(e) => this.editElectronicReportingFee(e)} checked={pmcTariffDataStore.isElectronicReportingFeeEditable} disabled={pmcTariffDataStore.isTariffSaved} />
                                                                </label>
                                                                </Col>
                                                            <Col>
                                                                <label className='standard_label_style totalLable'>Total</label>
                                                            <input
                                                                type='number'
                                                                step="0.01"
                                                                min="0.00"
                                                                className='search_text_field input'
                                                                value={pmcTariffDataStore.totalFee.toFixed(2)}
                                                                readOnly={true}
                                                                />
                                                                </Col>
                                                            </div>
                                                        <div style={{display:'inline-block'}}>
                                                            <label className="standard_label_style_note"><strong>Note:</strong><br></br>
                                                                (1) Fees with grey boxes are linked to System Fees which will auto populate if the tariff date range falls within
                                                                a preset. To override values, check the box to allow manual fee entry.<br></br>
                                                                (2) Only 2 decimals places allowed for all system fees.</label>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                                <Row>
                                                    <div className="rightFlexBottom">
                                                        <div style={{ display: pmcTariffDataStore.isTariffSaved && pmcTariffDataStore.pmcTariffDetailID ? 'none' : 'block' }}>
                                                            {/* <CustomButton type={CustomButtonType.Submit} onClick={() => { this.savePMCTariff(); }}> */}
                                                            <CustomButton type={CustomButtonType.Submit} onClick={() => { this.savePMCTariffDetailFeeDetail(); }}>
                                                                Save
                                                            </CustomButton>
                                                        </div>
                                                        <div style={{ display: !pmcTariffDataStore.isTariffSaved ? 'none' : 'block' }}>
                                                            <CustomButton  disabled={!pmcTariffDataStore.isCellEditable()} type={CustomButtonType.Submit} onClick={() => { this.onEditTariff(); }}>
                                                                Edit
                                                            </CustomButton>
                                                        </div>
                                                        <div style={{ display: !(pmcTariffDataStore.isTariffSaved && pmcTariffDataStore.pmcTariffDetailID && (!pmcTariffDataStore.selectedEffectiveEndDate || pmcTariffDataStore.selectedEffectiveEndDate == '')) ? 'none' : 'block' }}>
                                                            <CustomButton disabled={!pmcTariffDataStore.isCellEditable()} type={CustomButtonType.Submit} onClick={() => { this.duplicatePMCTariff(); }}>
                                                                Duplicate
                                                            </CustomButton>
                                                        </div>
                                                        <div style={{ display: pmcTariffDataStore.isDuplicateTariff && !pmcTariffDataStore.isTariffSaved && pmcTariffDataStore.pmcTariffDetailID ? 'none' : 'block' }}>
                                                            <CustomButton type={CustomButtonType.Submit} onClick={() => { pmcTariffDataStore.isTariffEditingEnabled ? this.onCancelEditTariff() : this.cancelPMCTariff() }}>
                                                                Cancel
                                                            </CustomButton>
                                                        </div>
                                                        <div style={{ display: pmcTariffDataStore.isDuplicateTariff && !pmcTariffDataStore.isTariffSaved && pmcTariffDataStore.pmcTariffDetailID ? 'block' : 'none' }}>
                                                            <CustomButton type={CustomButtonType.Submit} onClick={() => { this.deletePMCTariff(); }}>
                                                                Delete
                                                            </CustomButton>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                        </div>
                                        <Modal className='modal-lg' show={pmcTariffDataStore.isShowEndDateConfirmationModal} onHide={this.handleCloseEndDateConfirmationModal}>
                                            <Modal.Header className='screenHeader' closeButton>
                                            <Modal.Title className='screen_title'>Set Tariff End Date Confirmation</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                            <div>This Tariff's start date is in between the existing Tariff's date range with same Tariff Number. To continue with this start date, you need to set end date for existing tariff. Do you want to proceed?</div>
                                            </Modal.Body>
                                            <Modal.Footer>
                                            <Button variant="secondary" onClick={this.handleCloseEndDateConfirmationModal}>
                                                No
                                            </Button>
                                            <Button variant="primary" onClick={this.setTariffEndDate}>
                                                Yes
                                            </Button>
                                        </Modal.Footer>
                                        </Modal>
                                    </Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item eventKey='1'>
                                    <Accordion.Header>Notes & Attachments</Accordion.Header>
                                    <Accordion.Body>
                                        <div className='flex'>
                                            <div className="rotatedText">
                                                Notes
                                            </div>
                                            <div style={{ width: '100%' }}>
                                                <Row>
                                                    <AddNotes saveRoleAccess={() => pmcTariffDataStore.isCellEditable()} disableAddBtn={!pmcTariffDataStore.isTariffSaved || !pmcTariffDataStore.isCellEditable()} disableSaveBtn={!pmcTariffDataStore.isTariffNoteAdded || !pmcTariffDataStore.isTariffSaved || !pmcTariffDataStore.isCellEditable()} disableDeleteBtn={!pmcTariffDataStore.isCellEditable()} isNoteSaved={pmcTariffDataStore.isTariffNoteSaved} onAddNewNoteCallback={() => this.onAddNewNote()} onSaveNotesCallback={() => this.onSaveNotes()} onDeleteNoteCallback={(data) => this.onDeleteNote(data.pmcTariffNoteID)} agGridService={pmcTariffDataStore.agGridService} notesGridData={pmcTariffDataStore.notesGridData} cellValueChangeMap={pmcTariffDataStore.cellValueChangeMap} updateRow={(rowData) => pmcTariffDataStore.updateRow(rowData)} ></AddNotes>
                                                </Row>
                                            </div>
                                        </div>
                                        <hr></hr>
                                        <div className='flex'>
                                            <div className="rotatedText">
                                                Attachments
                                            </div>
                                            <div style={{ width: '100%' }}>
                                                <Row>
                                                    <Attachments saveRoleAccess={pmcTariffDataStore.isCellEditable()} disabled={!pmcTariffDataStore.isTariffSaved  || !pmcTariffDataStore.isCellEditable()} disableDeleteBtn={!pmcTariffDataStore.isCellEditable()} uploadFileCallback={(e) => this.uploadFile(e)} onDownloadFileCallback={(data) => this.downloadAttachment(data.pmcTariffDocumentID)} onUpdateAttachmentCallback={() => this.updateAttachment()} onDeleteFileCallback={(data) => this.deleteAttachment(data.pmcTariffDocumentID)} agGridService={pmcTariffDataStore.agGridService} fileUploadGridData={pmcTariffDataStore.fileUploadGridData}  cellValueChangeMap={pmcTariffDataStore.cellValueChangeMapAttachment} updateRow={(rowData) => pmcTariffDataStore.updateRowAttachment(rowData)}></Attachments>
                                                </Row>
                                            </div>
                                        </div>
                                        {/* <div>
                                            <hr></hr>
                                            <Row>
                                                <div className='rightFlexBottom'>
                                                    <CustomButton
                                                        type={CustomButtonType.Submit}
                                                        onClick={this.onHandleSubmitTariff}
                                                        disabled={!pmcTariffDataStore.isTariffSaved}
                                                    >
                                                        {PMCTariffButtons.SUBMIT_FOR_APPROVAL}
                                                    </CustomButton>
                                                </div>
                                            </Row>
                                        </div> */}
                                    </Accordion.Body>
                                </Accordion.Item>
                            </Accordion>
                        </div>
                    </Container >
                </div >
            );
        } else {
            return (
                <div>
                    <Row>
                        <Col className='TariffWorkflowCol'>
                            <label className='standard_label_style TariffWorkflowLabel'>Tariff Workflow Type</label>
                            <ReactSelect
                                className='select'
                                values={pmcTariffDataStore.selectedTariffRequestType}
                                placeholder="Select"
                                options={pmcTariffDataStore.tariffRequestTypeDropdownOptions}
                                onChange={this.onSelectTariffRequestType}
                                isMulti={false}
                                isSearchable={true}
                            />
                        </Col>
                    </Row>
                </div>
            );
        }
    }
}
