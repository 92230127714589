const apiClientID = process.env.API_CLIENT_ID ? process.env.API_CLIENT_ID : (window).hostName.API_CLIENT_ID ;
const tenantID = process.env.TENANT_ID ? process.env.TENANT_ID : (window).hostName.TENANT_ID;
const WebAppServerUrl = process.env.WEB_APP_HOST ? process.env.WEB_APP_HOST : (window).hostName.WEB_APP_HOST;
const msalEndpoint = process.env.MSAL_ENDPOINT? process.env.MSAL_ENDPOINT : (window).hostName.MSAL_ENDPOINT;
const FunctionAppHostName = process.env.FUNCTION_APP_HOST? process.env.FUNCTION_APP_HOST: (window).hostName.FUNCTION_APP_HOST;
const EBSData = process.env.EBS_DATA ? process.env.EBS_DATA : (window).hostName.EBS_DATA;
const ProjectRequestEmailDescriptionLink = process.env.Project_Request_Email_Description ?  process.env.Project_Request_Email_Description : (window).hostName.Project_Request_Email_Description;
const EBSDataValidate = process.env.EBS_DATA_VALIDATE ? process.env.EBS_DATA_VALIDATE : (window).hostName.EBS_DATA_VALIDATE;
const functionAppKey = process.env.FUNCTION_APP_KEY ? process.env.FUNCTION_APP_KEY : (window).hostName.FUNCTION_APP_KEY;
const vmacsSettingsApi = process.env.VMACS_SETTINGS_API_URL ?  process.env.VMACS_SETTINGS_API_URL : (window).hostName.VMACS_SETTINGS_API_URL;
const vmacsAgencyApi = process.env.VMACS_AGENCY_API_URL ?  process.env.VMACS_AGENCY_API_URL : (window).hostName.VMACS_AGENCY_API_URL;
const vmacsLocationApi = process.env.VMACS_LOCATION_API_URL ?  process.env.VMACS_LOCATION_API_URL : (window).hostName.VMACS_LOCATION_API_URL;
export const EMAIL_RECIPIENTS = process.env.EMAIL_RECIPIENTS ? process.env.EMAIL_RECIPIENTS : (window).hostName.EMAIL_RECIPIENTS;
export const CAN_EMAIL_RECIPIENTS = process.env.CAN_EMAIL_RECIPIENTS ? process.env.CAN_EMAIL_RECIPIENTS : (window).hostName.CAN_EMAIL_RECIPIENTS;
const vmacsCPApi = process.env.VMACS_CONNECTION_POINT_API_URL ?  process.env.VMACS_CONNECTION_POINT_API_URL : (window).hostName.VMACS_CONNECTION_POINT_API_URL;
const vmacsLSApi = process.env.VMACS_LINE_SEGMENT_API_URL ?  process.env.VMACS_LINE_SEGMENT_API_URL : (window).hostName.VMACS_LINE_SEGMENT_API_URL;
const vmacsProfitCenterApi = process.env.VMACS_PROFIT_CENTER_API_URL ? process.env.VMACS_PROFIT_CENTER_API_URL : (window).hostName.VMACS_PROFIT_CENTER_API_URL;
const vmacsGradeApi = process.env.VMACS_GRADE_API_URL ?  process.env.VMACS_GRADE_API_URL : (window).hostName.VMACS_GRADE_API_URL;
const vmacsFilingEntityApi = process.env.VMACS_FILING_ENTITY_API_URL ?  process.env.VMACS_FILING_ENTITY_API_URL : (window).hostName.VMACS_FILING_ENTITY_API_URL;
const vmacsCustomerApi = process.env.VMACS_CUSTOMER_API_URL ?  process.env.VMACS_CUSTOMER_API_URL : (window).hostName.VMACS_CUSTOMER_API_URL;

// const buildInfo = process.env.BUILD_INFO;
 const  Path = 
    {
      apiClientID,
      tenantID,
      msalEndpoint,
      redirectURL: `${WebAppServerUrl}/login`,
      'url_id1' : `${FunctionAppHostName}setting/GetSettings/All?location=`,

      'url_id2' : `${FunctionAppHostName}setting?location=`,
      
      'url_id3' : `${FunctionAppHostName}settingtype?location=`,
      
      'url_id4' : `${FunctionAppHostName}TariffAgency?jurisdiction=All&stateAbbr=All&agency=All&agencyCode=All`,

      'url_id5' : `${FunctionAppHostName}setting`,
      
      'url_id6' : `${FunctionAppHostName}TariffState`,
      
      'url_id7' : `${FunctionAppHostName}TariffState/GetStatesByCountry?countryName=USA`,
      
      'url_id8' : `${FunctionAppHostName}setting/GetSettings/Jurisdiction`,
      
      'url_id9' : `${FunctionAppHostName}TariffAgency`,

      'url_id10' : `${FunctionAppHostName}setting/GetSettings/Tariff Types`,
      
      'url_id11' : `${FunctionAppHostName}setting/GetSettings/Product Types`,

      'url_id12' : `${FunctionAppHostName}TariffAgency?`,

      'url_id13' : `${FunctionAppHostName}TariffEntity`,

      'url_id14' : `${FunctionAppHostName}TariffPipeline/GetPipelinesbyEntityId?entityId=`,

      'url_id15' : `${FunctionAppHostName}TariffBase`,

      'url_id16' : `${FunctionAppHostName}TariffBase/GetBaseDetails/`,
      
      'url_id17' : `${FunctionAppHostName}setting/GetSettings/Project Types`,

      'url_id18' : `${FunctionAppHostName}tarifflog/GetProjectNames`,

      'url_id19' : `${FunctionAppHostName}TariffLog/SaveLogDocument`,

      'url_id20' : `${FunctionAppHostName}TariffLog`,

      'tariffDownloadAttachment': `${FunctionAppHostName}TariffLog/DownloadDocument`,

      'tariffUpdateAttachment': `${FunctionAppHostName}TariffLog/UpdateDocument`,

      'tariffDeleteAttachment': `${FunctionAppHostName}TariffLog/DeleteFile`,

      'pipelineMaintenance_getPipelineGrid' : `${FunctionAppHostName}TariffPipeline/GetPipelineGrid`,

      'pipelineMaintenance_getPipelineTypes' : `${FunctionAppHostName}setting/GetSettings/Pipelines Types`,

      'pipelineMaintenance_getPhysicalPipelines' : `${FunctionAppHostName}TariffPipeline/GetPhysicalPipelines`,

      'addPipelines_reviewFrequency' : `${FunctionAppHostName}setting/GetSettings/Review Frequency`,

      'addPipelines_rateBasis' : `${FunctionAppHostName}setting/GetSettings/Rate Basis`,

      'addPipelines_lossGain' : `${FunctionAppHostName}setting/GetSettings/LossGainInd`,

      'addPipelinePLABasis' : `${FunctionAppHostName}setting/GetSettings/Invoice Basis`,

      'addPipelineAllocationBasis' : `${FunctionAppHostName}setting/GetSettings/Invoice Basis`,

      'addPipelineOwnersDropdown' : `${FunctionAppHostName}TariffCustomer`,

      'addPipelineLGToDropdown' : `${FunctionAppHostName}setting/GetSettings/Distribute`,

      'addPipelineSavePipeline' : `${FunctionAppHostName}TariffPipeline/SavePipeline`,

      'addPipelineSaveOwners' : `${FunctionAppHostName}TariffPipeline/SavePipelineOwners`,

      'addPipelineSaveEntities' : `${FunctionAppHostName}TariffPipeline/SavePipelineEntities`,

      'addPipelineEBSDATA' : `${EBSData}`,

      'addPipelineEBSDATAValidate' : `${EBSDataValidate}`,

      'editPipeline_getPipeline' : `${FunctionAppHostName}TariffPipeline/GetPipelineDetailsByID`,

      'editPipeline_UpdatePipeline' : `${FunctionAppHostName}TariffPipeline/UpdatePipeline`,

      'editPipeline_UpdatePipelineOwners' : `${FunctionAppHostName}TariffPipeline/UpdatePipelineOwners`,

      'editPipeline_UpdatePipelineEntities' : `${FunctionAppHostName}TariffPipeline/UpdatePipelineEntities`,

      'deletePipeline' : `${FunctionAppHostName}TariffPipeline/DeletePipeline`,

      'getLocationsWithDefaultFilter': `${FunctionAppHostName}TariffLocationRelPL/GetLocationGrid?baseSystemId=0&locationName=All&status=All`,

      'getLocations': `${FunctionAppHostName}TariffLocationRelPL/GetLocationGrid`,

      'getBaseSystems': `${FunctionAppHostName}TariffPipeline/GetBaseSystems`,

      'activateLocations': `${FunctionAppHostName}TariffLocationRelPL/ActivateLocations`,

      'getFercTypes': `${FunctionAppHostName}Setting/GetActiveSettings/Ferc Types`,

      'rateTypeGrid': `${FunctionAppHostName}RateType/GetRateTypeGrid`,

      'prSummaryAction': `${FunctionAppHostName}Setting/GetSettings/PR Summary Action`,

      'activityType': `${FunctionAppHostName}Setting/GetSettings/Activity Type`,

      'rateType': `${FunctionAppHostName}RateType`,

      'step_1_getPipelines': `${FunctionAppHostName}TariffPipeline/GetBaseSystems/`,

      'step_1_getBaseTariffs' : `${FunctionAppHostName}TariffBase`,

      'step_1_getWorkflowDetails': `${FunctionAppHostName}Workflow/GetWorkflowProjectRequestByWFID`,
      
      'step_1_saveWorkflowDetails': `${FunctionAppHostName}Workflow/SaveWorkflowProjectRequest`,

      'step_1_getWorkflowStatusGridDetails': `${FunctionAppHostName}Workflow/GetWorkflowStepDetails`,
      
      'step_1_getWorkflowHistoryStatusGridDetails': `${FunctionAppHostName}Workflow/GetWorkflowStepHistoryDetails`,
      
      'step_1_saveComments': `${FunctionAppHostName}Workflow/SaveComments`,

      'step_2_getTariffLog': `${FunctionAppHostName}TariffLog`,
      
      'step_1_sendEmail': `${ProjectRequestEmailDescriptionLink}`,
      
      'step_1_sendEmailWorkflow': `${FunctionAppHostName}Workflow/GetWorkflowActions`,

      'step_2_goToPrevious': `${FunctionAppHostName}Workflow/BackToPrevStep`,

      'step_2_linkWorkflow': `${FunctionAppHostName}Workflow/LinkWorkflow`, 

      'step_2_getLinkedWorkflow': `${FunctionAppHostName}Workflow/GetWFLinkerData`,

      'step_2_getLinkedBaseAndLog': `${FunctionAppHostName}Workflow/GetLinkerDetails`,

      'step_2_processWorkflowLog': `${FunctionAppHostName}TariffLog/ProcessWFLog`,

      'step_2_getInfraSummary': `${FunctionAppHostName}/Workflow/GetInfraSummary`,

      'step_2_unlinkWorkflow': `${FunctionAppHostName}Workflow/UnLinkWorkflow`, 

      'step_2_getOptionInfo': `${FunctionAppHostName}TariffLog/GetLogInfo`, 

      'step_2_merge': `${FunctionAppHostName}TariffOption/MergeOption`, 

      'getTariffRequestTypes': `${FunctionAppHostName}Workflow/GetWorkflowTypes`,
      
      'savePMCRightAngleTariffStaging': `${FunctionAppHostName}PMCTariffDetail/SavePMCRightAngleTariffStaging`,

      'url_id24': `${FunctionAppHostName}setting/GetActiveSettings/PMC Pipeline Region`,

      'url_id25': `${FunctionAppHostName}setting/GetActiveSettings/PMC Pipeline System`,

      'url_id26': `${FunctionAppHostName}PMCSystemGroup/GetSystemGroupDetails`,

      'url_id27': `${FunctionAppHostName}setting/GetActiveSettings/PMC Products`,

      'url_id28': `${FunctionAppHostName}setting/GetActiveSettings/PMC Distribution Type`,

      'getTariffApplications': `${FunctionAppHostName}setting/GetActiveSettings/PMC Tariff Application Types`,

      'url_id29': `${FunctionAppHostName}setting/GetActiveSettings/PMC Unit`,

      'url_id30': `${FunctionAppHostName}setting/GetActiveSettings/PMC Currency`,

      'getRegulator': `${FunctionAppHostName}setting/GetActiveSettings/PMC Regulator`,
      
      'getTariffStatus': `${FunctionAppHostName}PMCTariffDetail/GetPMCTariffDetailStatusList`,

      'getExternalSystems': `${FunctionAppHostName}Setting/GetActiveSettings/PMC External System`,

      'getPMCProductTypes' : `${FunctionAppHostName}setting/GetActiveSettings/PMC Product Types`,

       'savePMCTariff': `${FunctionAppHostName}PMCTariffDetail`,

      'savePMCTariffDetailFeeDetail': `${FunctionAppHostName}/PMCTariffDetail/SavePMCTariffDetailFeeDetail`,

      'deletePMCTariff': `${FunctionAppHostName}PMCTariffDetail/DeleteTariff`,

      'duplicatePMCTariff': `${FunctionAppHostName}PMCTariffDetail/DuplicateTariff`,

      'getPMCSystemFees': `${FunctionAppHostName}PMCSystemFees/GetSystemFee`,

      'savePMCFeeData': `${FunctionAppHostName}PMCTariffDetail/SavePMCTariffFeeDetail`,

      'savePMCTariffNotes': `${FunctionAppHostName}PMCTariffDetail/SavePMCTariffNotes`,
      'saveBulkPMCTariffNotes': `${FunctionAppHostName}PMCTariffDetail/SaveBulkPMCTariffNotes`,      

      'updatePMCTariffNote': `${FunctionAppHostName}PMCTariffDetail/UpdatePMCTariffNotes`,

      'deletePMCTariffNote': `${FunctionAppHostName}PMCTariffDetail/DeletePMCTariffNotes`,
      'deleteBulkPMCTariffNotes': `${FunctionAppHostName}PMCTariffDetail/DeleteBulkPMCTariffNotes`,

      'savePMCTariffDocument': `${FunctionAppHostName}PMCTariffDetail/SavePMCTariffDocument`,
      'saveBulkPMCTariffDocument': `${FunctionAppHostName}PMCTariffDetail/SaveBulkPMCTariffDocument`,

      'downloadAttachment': `${FunctionAppHostName}PMCTariffDetail/DownloadDocument`,

      'updateAttachment': `${FunctionAppHostName}PMCTariffDetail/UpdateDocument`,
      'UpdateBulkDocument': `${FunctionAppHostName}PMCTariffDetail/UpdateBulkDocument`,

      'deleteAttachment': `${FunctionAppHostName}PMCTariffDetail/DeleteFile`,
      'BulkDeleteFile': `${FunctionAppHostName}PMCTariffDetail/BulkDeleteFile`,

      'getPMCTariffNoteType': `${FunctionAppHostName}setting/GetActiveSettings/PMC Tariff Note Type`,

      'getPMCShrinkage': `${FunctionAppHostName}setting/GetActiveSettings/PMC Shrinkage`,

      'getPMCLossAllwance': `${FunctionAppHostName}setting/GetActiveSettings/PMC Loss Allowance`,

      'getSystemFeeDataWithFilter':`${FunctionAppHostName}PMCSystemFees/GetPMCSystemFee`,

      'saveSystemFeeDetails':`${FunctionAppHostName}PMCSystemFees`,

      'getFacilityCodes': `${FunctionAppHostName}setting/GetActiveSettings/PMC Facility`,

      'getDestination': `${FunctionAppHostName}setting/GetActiveSettings/PMC Destination`,

      'getLSD': `${FunctionAppHostName}setting/GetActiveSettings/PMC LSD`,

      'getPMCFeeTypes': `${FunctionAppHostName}PMCTariffDetail/GetPMCFeeTypes`,

      'getPMCTariffDetail': `${FunctionAppHostName}PMCTariffDetail`,

      'GetPMCTariffSetup': `${FunctionAppHostName}PMCWorkflow/GetPMCTariffSetup`,

      'getWFReviewTariffDetail': `${FunctionAppHostName}PMCTariffDetail/GetPMCTariffDetailReview`,
      
      'saveWFTariffNotes': `${FunctionAppHostName}Workflow/SaveWFNotes`,

      'deleteWFTariffNote': `${FunctionAppHostName}Workflow/DeleteWFNotes`,

      'saveWFTariffDocument': `${FunctionAppHostName}Workflow/SaveWFDocument`,

      'downloadWFAttachment': `${FunctionAppHostName}Workflow/DownloadWFDocument`,

      'updateWFAttachment': `${FunctionAppHostName}Workflow/UpdateWFDocument`,

      'deleteWFAttachment': `${FunctionAppHostName}Workflow/DeleteWFFile`,

      'updateBulkTariff': `${FunctionAppHostName}PMCTariffDetail/BulkUpdate`,

      'bulkDuplicateTariffCheck': `${FunctionAppHostName}PMCTariffDetail/BulkDuplicateTariffCheck`,
      
      'bulkDuplicateTariff': `${FunctionAppHostName}PMCTariffDetail/BulkDuplicateTariff`,
      
      'getPMCPipelineSystems': `${FunctionAppHostName}Setting/GetPMCPipelineSystems`,

      'getUserNames': `${FunctionAppHostName}Role/GetUsersByLocation`,

      'getPMCTariffDetailIDs': `${FunctionAppHostName}PMCTariffDetail/GetPMCTariffDetailIDs`,

      'getPMCDashboardDetails': `${FunctionAppHostName}PMCTariffDetail/GetPMCDashboardDetails`,

      'getOperators': `${FunctionAppHostName}PMCTariffDetail/GetOperators`,
      
      'vmacsSettingsEndpoint' : `${vmacsSettingsApi}`,

      'vmacsAgencyEndpoint' : `${vmacsAgencyApi}`,

      'vmacsLocationEndpoint' : `${vmacsLocationApi}`,

      'getRoleAccessData' : `${FunctionAppHostName}role/GetRolesByEmail?userEmail=`,

      'addLocationDetailsAPI' : `${FunctionAppHostName}TariffLocationRelPL`,

      'getCPTypes' : `${FunctionAppHostName}setting/GetSettings/CP Types`,

      'getCPRelationTypes' : `${FunctionAppHostName}setting/GetSettings/CP Rel Types`,

      'getActivityTypes' : `${FunctionAppHostName}setting/GetSettings/Activity Type`,

      'getCCTypes' : `${FunctionAppHostName}setting/GetSettings/CC Type`,

      'getConnCarrerOperator' : `${FunctionAppHostName}TariffCustomer/GetOperators`,

      'getConnectionPointData' : `${FunctionAppHostName}TariffCP`,

      'activateConnectionPoints': `${FunctionAppHostName}TariffCP/ActivateCP?user`,

      'getCTSStatus' : `${FunctionAppHostName}Setting/GetActiveSettings/CTS Status`,
      
      'getWorkflowDetails' : `${FunctionAppHostName}Workflow/GetWorkflowTypes`,

      'getPMCTariffRateTypes': `${FunctionAppHostName}Setting/GetSettings/PMC Tariff Rate Type`,
      
      'step0_postWorkflowDetails': `${FunctionAppHostName}Workflow/CreateWorkFlowInstanceData`,
      
      'savePMCRightAngleXref': `${FunctionAppHostName}PMCTariffDetail/SavePMCRightAngleXref`,
      
      'removePMCRightAngleXref': `${FunctionAppHostName}PMCTariffDetail/RemovePMCRightAngleXref`,

      'getPMCRightAngleXref': `${FunctionAppHostName}PMCTariffDetail/GetPMCRightAngleXref`,
      
      'getRAName': `${FunctionAppHostName}PMCTariffDetail/GetRAName`,
          
      'getPMCRightAngleXrefSupplementaryData': `${FunctionAppHostName}PMCTariffDetail/GetPMCRightAngleXrefSupplementaryData`,
      
      'savePMCWorkflowSelectedTariffDetails': `${FunctionAppHostName}PMCTariffDetail/SavePMCTariffDetailSelected`,
      
      'getPMCWorkflowSelectedTariffDetails': `${FunctionAppHostName}PMCTariffDetail/GetPMCTariffDetailSelected`,
      
      'getWorkflowNotesByWFID': `${FunctionAppHostName}Workflow/GetWorkflowNotesByWFID`,
      
      'getWorkflowDocumentByWFID': `${FunctionAppHostName}Workflow/GetWorkflowDocumentByWFID`,

      'getGradesWithDefaultFilter': `${FunctionAppHostName}TariffGrade/GetTariffGradeGrid?grade=All&gradeName=All&pipelineSystemId=0`,

      'getGrades': `${FunctionAppHostName}TariffGrade/GetTariffGradeGrid`,
      
      'getSystemGrades': `${FunctionAppHostName}TariffGrade/GetSystemGrades`,

      'saveTariffGrade': `${FunctionAppHostName}TariffGrade/SaveTariffGrade`,

      'getTariffGrade': `${FunctionAppHostName}TariffGrade/GetTariffGrade`,

      'deleteTariffGrade': `${FunctionAppHostName}TariffGrade/DeleteTariffGrade`,
      
      'activateTariffGrade': `${FunctionAppHostName}TariffGrade/ActivateTariffGrade`,
      
      'getTariffEntity': `${FunctionAppHostName}TariffEntity`,

      'getPipelineSystem': `${FunctionAppHostName}TariffEntity/GetPipelineSystem`,

      'getTariffEntityGrid': `${FunctionAppHostName}TariffEntity/GetTariffEntityGrid`,
      
      'getTariffPipelineOwner': `${FunctionAppHostName}TariffEntity/GetTariffPipelineOwner`,

      'getTariffEntityDetails': `${FunctionAppHostName}TariffEntity/GetTariffEntityDetails`,

      'saveTariffEntity': `${FunctionAppHostName}TariffEntity/SaveTariffEntity`,
      
      'vmacsFilingEntityEndpoint' : `${vmacsFilingEntityApi}`,
      
      'getRulesRegTariff': `${FunctionAppHostName}TariffShipperLG/GetRulesRegTariff`,

      'getTariffShipperLGGrid': `${FunctionAppHostName}TariffShipperLG/GetTariffShipperLGGrid`,

      'getProfitCenterData': `${FunctionAppHostName}ProfitCenter`,

      'getLineSegmentData': `${FunctionAppHostName}LineSegment`,
      
      'getLineSegmentDetails': `${FunctionAppHostName}LineSegment/GetTariffLineSegment`,

      'getLineSegmentTypeData': `${FunctionAppHostName}Setting/GetActiveSettings/Line Seg Types`,

      'getLineSegmentStatusData': `${FunctionAppHostName}Setting/GetActiveSettings/CTS Status`,

      'getLineSegmentSystemAreaData': `${FunctionAppHostName}TariffPipeline/GetActivePipelines`,
      
      'saveTariffLineSegment': `${FunctionAppHostName}LineSegment/SaveTariffLineSegment`,

      'deactivateTariffLineSegment': `${FunctionAppHostName}LineSegment/DeleteTariffLineSegment`,

      'activateTariffLineSegment': `${FunctionAppHostName}LineSegment/ActivateTariffLineSegment`,
      
      'deleteLineSegmentRemark': `${FunctionAppHostName}LineSegment/DeleteComments`,
      
      'getActivePipelines': `${FunctionAppHostName}TariffPipeline/GetActivePipelines`,
      
      'saveTariffIndexRateData': `${FunctionAppHostName}TariffIndexRate/SaveTariffIndexRate`,

      'getTariffIndexRateData': `${FunctionAppHostName}TariffIndexRate/GetTariffIndexRate`,

      'getAssetGroupData': `${FunctionAppHostName}Workflow/GetWorkflowTypes`,

      'getLogEntryTypeData': `${FunctionAppHostName}Setting/GetActiveSettings/Log%20Entry%20Types`,
      
      'tariffOptions_GetTariffOptions': `${FunctionAppHostName}TariffOption/GetOptionDetails`,

      'getWorkflowOpenProjectsRequests': `${FunctionAppHostName}Workflow/GetWorkflowOpenProjectsRequests`,

      'getWorkflowStatus': `${FunctionAppHostName}Workflow/GetWorkflowStatus`,

      'getProjectRequestDashboardData': `${FunctionAppHostName}Workflow/GetWorkflowProjectsRequests`,

      'tariffOptions_GetCancelledTariff': `${FunctionAppHostName}TariffOption/GetTariffNos`,

      'tariffOptions_GetAgencyDropdown': `${FunctionAppHostName}TariffAgency?jurisdiction=USA&agency=All&agencycode=All`,

      'tariffOptions_GetStatusDropdown': `${FunctionAppHostName}setting/GetActiveSettings/Tariff Status`,
      
      'tariffOptions_GetTariffTypesDropdown': `${FunctionAppHostName}Setting/GetActiveSettings/Tariff%20Types `,

      'tariffOptions_GetCompanyDropdown': `${FunctionAppHostName}TariffEntity`,

      'tariffOptions_SaveComment': `${FunctionAppHostName}TariffOption/SaveOptionComment`,

      'tariffOptions_GetInvoiceBasisDropdown': `${FunctionAppHostName}Setting/GetActiveSettings/Invoice%20Basis`,

      'tariffOptions_GetPipelineAreasDropdown': `${FunctionAppHostName}TariffPipeline/GetBaseSystems`,

      // 'tariffOptions_GetAdministratorDropdown': `${FunctionAppHostName}TariffCustomer`,

      'tariffOptions_MovementGridLineSegment': `${FunctionAppHostName}LineSegment`,
      
      'tariffOptions_MovementGridTALineSegment': `${FunctionAppHostName}LineSegment/GetTALineSegments`,
      
      'tariffOptions_MovementGridRateBasis': `${FunctionAppHostName}Setting/GetActiveSettings/Rate%20Basis`,
      
      'tariffOptions_TariffIndexRate': `${FunctionAppHostName}TariffIndexRate/GetTariffIndexRate`,
      
      'tariffOptions_MovementGridRateType': `${FunctionAppHostName}RateType`,

      'tariffOptions_MovementGridRateTypeDirection': `${FunctionAppHostName}Setting/GetActiveSettings/Rate%20Type%20Direction`,

      'tariffOptions_ConnectionPointGridLocation': `${FunctionAppHostName}TariffLocationRelPL/GetLocationGrid`,
      
      'tariffOptions_ConnectionPointGridCP': `${FunctionAppHostName}TariffCP`,
      
      'tariffOptions_saveTariffOptions': `${FunctionAppHostName}TariffOption`,

      'tariffOptions_saveTariffOptionsIndex': `${FunctionAppHostName}TariffOption/SaveLogOption`,

      'tariffOptions_editTariffOptions': `${FunctionAppHostName}TariffOption/UpdateOption`,
      
      'tariffOptions_saveDeduction': `${FunctionAppHostName}TariffOption/SaveDedOption`,

      'tariffOptions_saveLossAllowance': `${FunctionAppHostName}TariffOption/SaveLAOption`,

      'tariffOptions_saveRelatedTariff': `${FunctionAppHostName}TariffOption/SaveRelOption`,

      'tariffOptions_savePipelineArea': `${FunctionAppHostName}TariffOption/SavePLOption`,

      'tariffOptions_saveOwner': `${FunctionAppHostName}TariffOption/SaveOwnerOption`,

      'tariffOptions_saveMovement': `${FunctionAppHostName}TariffOption/SaveMvmtOption`,

      'tariffOptions_saveConnectionPoint': `${FunctionAppHostName}TariffOption/SaveCPOption `,

      'tariffOptions_tariff_relation_types': `${FunctionAppHostName}setting/GetActiveSettings/Tariff Relation types`,

      'tariffOptions_ownerDropdown': `${FunctionAppHostName}TariffCustomer`,

      'tariffOptions_deactivateOption': `${FunctionAppHostName}TariffOption/DeactivateOption`,

      'tariffOptions_cancelOption': `${FunctionAppHostName}TariffOption/CancelOption`,
      
      'tariffOptions_cloneOption': `${FunctionAppHostName}TariffOption/CloneOption`,

      'getBaseTariffJointCarriers' : `${FunctionAppHostName}TariffBase?includeJT=true`,

      'getInprogressTariffLog' : `${FunctionAppHostName}TariffLog/GetTariffLogInProgress`,
      
      'tariffOptions_deleteDeduction': `${FunctionAppHostName}TariffOption/DeleteDedOption`,

      'tariffOptions_deleteLossAllowance': `${FunctionAppHostName}TariffOption/DeleteLAOption`,

      'tariffOptions_deleteRelatedTariff': `${FunctionAppHostName}TariffOption/DeleteRelOption`,

      'tariffOptions_deletePipeline': `${FunctionAppHostName}TariffOption/DeletePLOption`,

      'tariffOptions_deleteOwner': `${FunctionAppHostName}TariffOption/DeleteOwnOption`,

      'tariffOptions_deleteCP': `${FunctionAppHostName}TariffOption/DeleteCPOption`,

      'tariffOptions_deleteMovement': `${FunctionAppHostName}TariffOption/DeleteMvmtOption`,

      'tariffOptions_deleteComments': `${FunctionAppHostName}TariffOption/DeleteOptionComments`,

      'getTariffDashboardData': `${FunctionAppHostName}TariffLog/GetTariffs`,

      'getTariffLogStatusData': `${FunctionAppHostName}setting/GetActiveSettings/Log Status`,

      'createLogEntryWorkFlowGridDetails': `${FunctionAppHostName}Workflow/CreateWorkFlowInstanceData`,

      'tariffDashboardProcessLog' : `${FunctionAppHostName}TariffLog/ProcessLog`,

      'saveLogEntry' : `${FunctionAppHostName}TariffLog/SaveLogEntry`,

      'cancelWorkflow' : `${FunctionAppHostName}Workflow/CancelWorkflow`,

      'updateWFTariffLogInfo' : `${FunctionAppHostName}Workflow/UpdateWFTariffLogInfo`,

      'saveTestDetermination' : `${FunctionAppHostName}TariffLog/SaveTestDetermination`,

      'getLog1DStatus' : `${FunctionAppHostName}TariffLog/GetLog1DStatus`,

      'getHomeScreenTariffOption' : `${FunctionAppHostName}TariffOption/GetOptionDashboard`,

      'getProjectActionCenter' : `${FunctionAppHostName}Workflow/GetProjectActionCenter?userEmail=`,

      'saveConnectionPoint' : `${FunctionAppHostName}TariffCP/SaveCP`,

      'vmacsCPEndpoint' : `${vmacsCPApi}`,

      'vmacsCustomerEndpoint' : `${vmacsCustomerApi}`,
      
      'vmacsLineSegmentEndpoint' : `${vmacsLSApi}`,
      
      'vmacsGradeEndpoint' : `${vmacsGradeApi}`,

      'deleteCP' : `${FunctionAppHostName}TariffCP/DeleteCP`,

      'getCustomerTypes' : `${FunctionAppHostName}setting/GetActiveSettings/Customer Types`,

      'getCustomerStatus' : `${FunctionAppHostName}setting/GetActiveSettings/CTS Status`,

      'checkCustomerName' : `${FunctionAppHostName}TariffCustomer/GetCustomers`,

      'phoneNumberTypes': `${FunctionAppHostName}setting/GetActiveSettings/Phone%20No%20Types`,

      'addressTypes': `${FunctionAppHostName}setting/GetActiveSettings/Address%20Type`,

      'custAddressTypes': `${FunctionAppHostName}setting/GetActiveSettings/Customer%20Address%20Type`,

      'custCommentTypes': `${FunctionAppHostName}setting/GetActiveSettings/Customer%20Comment%20Type`,

      'contactTypes': `${FunctionAppHostName}setting/GetActiveSettings/Contact%20Type`,

      'creditTypes': `${FunctionAppHostName}setting/GetActiveSettings/Credit%20Type`,

      'activateCustomer': `${FunctionAppHostName}TariffCustomer/ActiveCustomer`,

      'saveCustomer': `${FunctionAppHostName}TariffCustomer/SaveCustomerDetail`,

      'deactivateCustomer': `${FunctionAppHostName}TariffCustomer/DeactiveCustomer`,

      'autoUpdateWorkflowStepstatusComplete' : `${FunctionAppHostName}Workflow/AutoUpdateWorkflowStepstatusComplete`,

      'autoUpdateWorkflowStepstatusReject' : `${FunctionAppHostName}Workflow/AutoUpdateWorkflowStepstatusReject`,
      
      'getCustomerMaintenanceGrid': `${FunctionAppHostName}TariffCustomer/GetCustomerMaintGrid`,

      'getFilingCarrier' : `${FunctionAppHostName}FilingCarrier`,

      'saveTariffProfitCenter' : `${FunctionAppHostName}ProfitCenter/SaveTariffProfitCenter`,

      'getTariffProfitCenter' : `${FunctionAppHostName}ProfitCenter/GetTariffProfitCenter`,

      'getTariffRelProfitCenter' : `${FunctionAppHostName}ProfitCenter/GetTariffRelProfitCenter`,

      'getBaseSystemsProfitCenter' : `${FunctionAppHostName}ProfitCenter/GetBaseSystemsProfitCenter`,

      'vmacsProfitCenterEndpoint' : `${vmacsProfitCenterApi}`,
      
      'getRulesRegOptions' : `${FunctionAppHostName}TariffOption/GetRulesRegOptions`,

      'getParentStatus' : `${FunctionAppHostName}Workflow/GetParentStatus`,

      'saveMaintenanceLogEntry' : `${FunctionAppHostName}TariffLog/SaveMaintenanceLog`,

      'getTariffCustomerEnomCode' : `${FunctionAppHostName}TariffCustomer/GetEnomCode?customerID=`,

      'tariffCust_deleteAddress': `${FunctionAppHostName}TariffCustomer/DeleteCustAddress?user=`,

      'tariffCust_deleteCustAffliates': `${FunctionAppHostName}TariffCustomer/DeleteCustAffliates?user=`,

      'tariffCust_deleteCustAddInf': `${FunctionAppHostName}TariffCustomer/DeleteCustAddInfo?user=`,

      'tariffCust_deleteCustComments': `${FunctionAppHostName}}TariffCustomer/DeleteCustComments?user=`,
      
      'getFilingCarriersGrid' : `${FunctionAppHostName}FilingCarrier/GetFilingCarriersGrid`,

      'getFilingCarriersDetails' : `${FunctionAppHostName}FilingCarrier/GetFilingCarriersDetails`,

      'saveFilingCarrier' : `${FunctionAppHostName}FilingCarrier/SaveFilingCarrier`,

      'deleteFilingCarrier' : `${FunctionAppHostName}FilingCarrier/DeleteFilingCarrier`,

      'activateFilingCarrier' : `${FunctionAppHostName}FilingCarrier/ActivateFilingCarrier`,

      'getFilingCarrierEntity' : `${FunctionAppHostName}FilingCarrier/GetFilingEntity`,

      'getFilingCarrierRelPipelinesByCustomerID' : `${FunctionAppHostName}FilingCarrier/GetFilingCarrierRelPipelinesByCustomerID`,

      'getFilingCarrierRelPipelinesByEntityID' : `${FunctionAppHostName}FilingCarrier/GetFilingCarrierRelPipelinesByEntityID`,
    }

    export default Path;

    export const MiscellaneusSettingsDBTableName = {
      TableName : 'Setting',
      MappingTableName :'TariffSettingMapping'
    }

    export const AgencySettingsDBTableName = {
      TableName : 'TariffAgency',
      MappingTableName :'TariffMapping'
    }

    export const LocationSettingsDBTableName = {
      TableName : 'TariffLocation',
      MappingTableName :'TariffMapping'
    }

    export const TariffRateTypesDBTableName = {
      TableName : 'TariffRateType',
      MappingTableName :'TariffMapping'
    }
    
    export const PipelineMaintenanceDBTableName = {
      TableName : 'TariffPipeline',
      MappingTableName :'TariffMapping'
    }
